import { Moment } from 'moment';
import { ChatChannelType } from '../chat/chatTypes';
import { ErrorPayload } from '../common/types';
import { createAdHocBatchContentActions } from '../content/contentActions';
import { AdHocContentRequest, ProgramType } from '../content/contentTypes';
import { DeviceShipment } from '../device/deviceTypes';
import { ThunkParams } from '../types';
import {
  calculateSleepScheduleActions,
  getAllPermissionRolesActions,
  getPersonAndPermissionsByOrgIdAction,
  getPersonByIdActions,
  getPersonDetailsActions,
  getPersonOverviewActions,
  getPersonOverviewCountActions,
  getPersonPermissionsActions,
  getPersonPermissionsByEmailActions,
  getPersonSettingsActions,
  getPersonSettingsProgramByEmailActions,
  getSleepSchedulesActions,
  getValidSleepSchedulesActions,
  postNewPatientAction,
  postNewTeamMemberAction,
  putAccountStatusActions,
  putChatBotEnabledActions,
  putDeviceGroupActions,
  putEnableDeviceTrainingActions,
  putEsIdActions,
  putLunaSleepLogSummariesEnabledActions,
  putLunaSleepScheduleSummariesEnabledActions,
  putLunaWeeklySummariesEnabledActions,
  putMobileCustomSleepStoriesEnabledActions,
  putPersonPermissionRoleActions,
  putShowSleepFeaturesActions,
  putSleepLogAutoSubmissionEnabledActions,
  putSleepLogDeviceTimesEnabledActions,
  putSleepScheduleEnabledActions,
  removePersonPermissionRoleActions,
  saveIsiScoreActions,
  saveOrderActions,
  saveShipmentActions,
  saveSleepScheduleAndCalculationsActions,
  sendPersonPushNotificationActions,
  sendPushNotificationsActions,
  updatePersonProgramStepActions,
  updatePersonSettingsActions,
  updateSleepScheduleActions,
  userPermissionsActions,
  getPersonVitalsAction
  // userPermissionsActions
} from './personActions';
import { getPersonByIdSelector } from './personSelector';
import {
  DeviceGroup,
  NewPatientRequest,
  NewTeamMemberRequest,
  PermissionRole,
  Person,
  PersonAccountStatus,
  PersonDetailsResponse,
  PersonIsiScore,
  PersonOrder,
  PersonOverviewFilters,
  PersonOverviewResponse,
  PersonPermissionRole,
  PersonPermissionsResponse,
  PersonSetting,
  PersonVitals,
  PushNotificationRequest,
  SleepCalculationRequest,
  SleepSchedule,
  SleepScheduleCalculation,
  SleepScheduleCalculationRequest,
  WorkflowUpdatingResponse
} from './personTypes';

export const DEFAULT_ROW_CACHE_MINUTES: number = 10;
export const START_PAGE: number = 0;
export const DEFAULT_ROWS_PER_PAGE: number = 100;

function processGetPersonByIdSuccess(payload: Person): Function {
  return function (dispatch: Function): void {
    dispatch(getPersonByIdActions.success(payload));
  };
}
function processGetPersonByIdFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getPersonByIdActions.fail(error));
  };
}
export function getPersonByIdThunk(personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    const person = getPersonByIdSelector(getState())[personId];
    if (person && person.lastUpdated && person.lastUpdated > Date.now() - DEFAULT_ROW_CACHE_MINUTES * 60 * 1000) {
      return Promise.resolve();
    }
    dispatch(getPersonByIdActions.start());
    return apiClient.get(`/v1/person/${personId}`, {
      auth: true,
      successAction: processGetPersonByIdSuccess,
      failAction: processGetPersonByIdFail
    });
  };
}

export function processCreateNewPatientSuccess(newPatientResponse: Person): Function {
  return function (dispatch: Function): void {
    dispatch(postNewPatientAction.success(newPatientResponse));
  };
}
function processCreateNewPatientThunkFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(postNewPatientAction.fail(error));
  };
}
export function postNewPatientThunk(newPatientRequest: NewPatientRequest) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(postNewPatientAction.start());
    return apiClient.post(`v1/person/newPatient`, {
      auth: true,
      data: newPatientRequest,
      successAction: processCreateNewPatientSuccess,
      failAction: processCreateNewPatientThunkFail
    });
  };
}

export function processCreateNewTeamMemberSuccess(newTeamMemberResponse: Person): Function {
  return function (dispatch: Function): void {
    dispatch(postNewTeamMemberAction.success(newTeamMemberResponse));
  };
}

function processCreateNewTeamMemberFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(postNewTeamMemberAction.fail(error));
  };
}

export function postNewTeamMemberThunk(newTeamMemberRequest: NewTeamMemberRequest) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(postNewTeamMemberAction.start());
    return apiClient.post(`v1/person/newTeamMember`, {
      auth: true,
      data: newTeamMemberRequest,
      successAction: processCreateNewTeamMemberSuccess,
      failAction: processCreateNewTeamMemberFail
    });
  };
}

function processGetPersonSettingsAndProgramByEmailSuccess(payload: Person): Function {
  return function (dispatch: Function): void {
    dispatch(getPersonSettingsProgramByEmailActions.success(payload));
  };
}
function processGetPersonSettingsAndProgramByEmailFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getPersonSettingsProgramByEmailActions.fail(error));
  };
}
export function getPersonProgramAndSettingsByEmailThunk(email: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getPersonSettingsProgramByEmailActions.start());
    let params = { email };
    return apiClient.get(`/v1/person/programAndSettingsByEmail`, {
      auth: true,
      params: params,
      successAction: processGetPersonSettingsAndProgramByEmailSuccess,
      failAction: processGetPersonSettingsAndProgramByEmailFail
    });
  };
}

function processGetUserPermissionsSuccess(payload: PermissionRole[]): Function {
  return function (dispatch: Function): void {
    dispatch(userPermissionsActions.success(payload));
  };
}
function processGetUserPermissionsFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(userPermissionsActions.fail(error));
  };
}
export function getUserPermissionsThunk() {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(userPermissionsActions.start());
    return apiClient.get('/v1/person/permissionRoles', {
      auth: true,
      successAction: processGetUserPermissionsSuccess,
      failAction: processGetUserPermissionsFail
    });
  };
}

// function processUpsertPersonPermissionSuccess(permissionRoleTypeField: string, personPermissionRole: PersonPermissionRole): Function {
//   return function (dispatch: Function): void {
//     dispatch(permissionRoleUpsertActions.success({ permissionRoleTypeField, personPermissionRole}));
//   };
// }
// function processUpsertPersonPermissionFail(error: ErrorPayload): Function {
//   return function (dispatch: Function) {
//     dispatch(permissionRoleUpsertActions.fail(error));
//   };
// }
// export function upsertPersonPermissionThunk(permissionRoleTypeField: string, personPermissionRole: PersonPermissionRole): Function {
//   return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
//     dispatch(permissionRoleUpsertActions.start());
//     return apiClient.post(`/v1/person/permissionRole`, {
//       auth: true,
//       data: personPermissionRole,
//       successAction: (personPermissionRole) => processUpsertPersonPermissionSuccess(permissionRoleTypeField, personPermissionRole),
//       failAction: processUpsertPersonPermissionFail
//     });
//   };
// }

function processUpdatePersonProgramSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(updatePersonProgramStepActions.success());
  };
}
function processUpdatePersonProgramFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(updatePersonProgramStepActions.fail(error));
  };
}

export function updatePersonProgramStepThunk(personId: string, programType: ProgramType, programStep: number): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(updatePersonProgramStepActions.start);
    return apiClient.put(`/v1/person/${personId}/programStep/${programStep}`, {
      auth: true,
      params: {
        'program-type': programType
      },
      successAction: processUpdatePersonProgramSuccess,
      failAction: processUpdatePersonProgramFail
    });
  };
}

function processUpdatePersonSettingsSuccess(payload: PersonSetting): Function {
  return function (dispatch: Function): void {
    dispatch(updatePersonSettingsActions.success(payload));
  };
}
function processUpdatePersonSettingsFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(updatePersonSettingsActions.fail(error));
  };
}

export function updatePersonSettingsThunk(personSettings: PersonSetting): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(updatePersonSettingsActions.start());
    return apiClient.put(`/v1/person/settings`, {
      auth: true,
      data: personSettings,
      successAction: processUpdatePersonSettingsSuccess,
      failAction: processUpdatePersonSettingsFail
    });
  };
}

function processUpdateSleepScheduleSuccess(payload: SleepSchedule): Function {
  return function (dispatch: Function): void {
    dispatch(updateSleepScheduleActions.success(payload));
  };
}
function processUpdateSleepScheduleFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(updateSleepScheduleActions.fail(error));
  };
}
export function updateSleepScheduleThunk(sleepSchedule: SleepSchedule): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(updateSleepScheduleActions.start());
    return apiClient.post(`/v1/person/sleepSchedule`, {
      auth: true,
      data: sleepSchedule,
      successAction: processUpdateSleepScheduleSuccess,
      failAction: processUpdateSleepScheduleFail
    });
  };
}

function processGetSleepSchedulesSuccess(payload: SleepSchedule[]): Function {
  return function (dispatch: Function): void {
    dispatch(getSleepSchedulesActions.success(payload));
  };
}
function processGetSleepSchedulesFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getSleepSchedulesActions.fail(error));
  };
}
export function getSleepSchedulesThunk(personId: string, count?: Nullable<number>) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getSleepSchedulesActions.start());
    return apiClient.get(`/v1/person/${personId}/sleepSchedules`, {
      auth: true,
      params: {
        count: count
      },
      successAction: processGetSleepSchedulesSuccess,
      failAction: processGetSleepSchedulesFail
    });
  };
}

function processGetPersonSettingsSuccess(payload: PersonSetting): Function {
  return function (dispatch: Function): void {
    dispatch(getPersonSettingsActions.success(payload));
  };
}
function processGetPersonSettingsFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getPersonSettingsActions.fail(error));
  };
}

export function getPersonSettingsThunk(): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getPersonSettingsActions.start());
    return apiClient.get(`/v1/person/settings`, {
      auth: true,
      successAction: processGetPersonSettingsSuccess,
      failAction: processGetPersonSettingsFail
    });
  };
}

function processSendPersonPushNotificationSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(sendPersonPushNotificationActions.success());
  };
}
function processSendPersonPushNotificationFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(sendPersonPushNotificationActions.fail(error));
  };
}
export function sendPersonPushNotificationThunk(personId: string, title: string, body: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(sendPersonPushNotificationActions.start());
    const pushNotificationRequest: PushNotificationRequest = {
      title,
      body
    };
    return apiClient.post(`/v1/person/${personId}/pushNotification`, {
      auth: true,
      data: pushNotificationRequest,
      successAction: processSendPersonPushNotificationSuccess,
      failAction: processSendPersonPushNotificationFail
    });
  };
}

function processSendPushNotificationsSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(sendPushNotificationsActions.success());
  };
}
function processSendPushNotificationsFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(sendPushNotificationsActions.fail(error));
  };
}
export function sendPushNotificationsThunk(personIds: string[], title: string, body: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(sendPushNotificationsActions.start());
    const pushNotificationRequest: PushNotificationRequest = {
      title,
      body,
      personIds
    };
    return apiClient.post(`/v1/person/pushNotification/batch`, {
      auth: true,
      data: pushNotificationRequest,
      successAction: processSendPushNotificationsSuccess,
      failAction: processSendPushNotificationsFail
    });
  };
}

function processCreateAdHocBatchContentSuccess(proceedToPushNotification?: Nullable<boolean>): Function {
  return function (dispatch: Function): void {
    dispatch(createAdHocBatchContentActions.success(proceedToPushNotification));
  };
}
function processCreateAdHocBatchContentFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(createAdHocBatchContentActions.fail(error));
  };
}
export function getCreateAdHocBatchContentThunk(adHocContentRequest: AdHocContentRequest, proceedToPushNotification?: Nullable<boolean>) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(createAdHocBatchContentActions.start());
    return apiClient.post(`/v1/content/adHoc/batch`, {
      auth: true,
      data: adHocContentRequest,
      successAction: () => processCreateAdHocBatchContentSuccess(proceedToPushNotification),
      failAction: processCreateAdHocBatchContentFail
    });
  };
}

function processCalculateSleepScheduleSuccess(payload: SleepScheduleCalculation): Function {
  return function (dispatch: Function): void {
    dispatch(calculateSleepScheduleActions.success(payload));
  };
}
function processCalculateSleepScheduleFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(calculateSleepScheduleActions.fail(error));
  };
}
export function calculateSleepScheduleThunk(personId: string, date: Moment, sleepScheduleCalculationRequest: SleepCalculationRequest) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(calculateSleepScheduleActions.start());
    return apiClient.post(`/v1/person/${personId}/calculateLast7DaysSleepSchedule?date=${date.format('yyyy-MM-DD')}`, {
      auth: true,
      data: sleepScheduleCalculationRequest,
      successAction: processCalculateSleepScheduleSuccess,
      failAction: processCalculateSleepScheduleFail
    });
  };
}

function processSaveSleepScheduleAndCalculationSuccess(
  sleepScheduleCalculation: SleepScheduleCalculation,
  ignoreWarnings: boolean,
  sleepSchedule: WorkflowUpdatingResponse<SleepSchedule>
): Function {
  return function (dispatch: Function): void {
    dispatch(saveSleepScheduleAndCalculationsActions.success({ sleepScheduleCalculation, ignoreWarnings, sleepScheduleUpdateResponse: sleepSchedule }));
  };
}
function processSaveSleepScheduleAndCalculationFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(saveSleepScheduleAndCalculationsActions.fail(error));
  };
}
export function saveSleepScheduleAndCalculationThunk(personId: string, sleepScheduleCalculationRequest: SleepScheduleCalculationRequest) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(saveSleepScheduleAndCalculationsActions.start());
    return apiClient.post(`/v1/person/${personId}/sleepScheduleAndCalculation`, {
      auth: true,
      data: sleepScheduleCalculationRequest,
      successAction: (sleepSchedule: WorkflowUpdatingResponse<SleepSchedule>) =>
        processSaveSleepScheduleAndCalculationSuccess(sleepScheduleCalculationRequest.sleepScheduleCalculation, sleepScheduleCalculationRequest.ignoreWarnings, sleepSchedule),
      failAction: processSaveSleepScheduleAndCalculationFail
    });
  };
}

function processGetPersonOverviewSuccess(payload: Array<PersonOverviewResponse>): Function {
  return function (dispatch: Function): void {
    dispatch(getPersonOverviewActions.success(payload));
  };
}
function processGetPersonOverviewFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getPersonOverviewActions.fail(error));
  };
}
export function getPersonOverviewThunk(personOverviewFilters: PersonOverviewFilters) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getPersonOverviewActions.start(!personOverviewFilters?.offset));
    return apiClient.post(`/v1/person/personOverview`, {
      auth: true,
      data: personOverviewFilters,
      successAction: processGetPersonOverviewSuccess,
      failAction: processGetPersonOverviewFail
    });
  };
}

function processGetPersonOverviewCountSuccess(payload: number): Function {
  return function (dispatch: Function): void {
    dispatch(getPersonOverviewCountActions.success(payload));
  };
}
function processGetPersonOverviewCountFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getPersonOverviewCountActions.fail(error));
  };
}
export function getPersonOverviewCountThunk(personOverviewFilters: PersonOverviewFilters): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getPersonOverviewCountActions.start());
    return apiClient.post(`/v1/person/personOverviewCount`, {
      auth: true,
      data: personOverviewFilters,
      successAction: processGetPersonOverviewCountSuccess,
      failAction: processGetPersonOverviewCountFail
    });
  };
}

export function processGetPersonDetailsSuccess(payload: PersonDetailsResponse): Function {
  return function (dispatch: Function): void {
    dispatch(getPersonDetailsActions.success(payload));
  };
}
function processGetPersonDetailsFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getPersonDetailsActions.fail(error));
  };
}
export function getPersonDetailsThunk(personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getPersonDetailsActions.start());
    return apiClient.get(`v1/person/personDetails`, {
      auth: true,
      params: {
        'person-id': personId
      },
      successAction: processGetPersonDetailsSuccess,
      failAction: processGetPersonDetailsFail
    });
  };
}

export function processPutAccountStatusSuccess(payload: WorkflowUpdatingResponse<PersonAccountStatus>): Function {
  return function (dispatch: Function): void {
    dispatch(putAccountStatusActions.success(payload));
  };
}
function processPutAccountStatusFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putAccountStatusActions.fail(error));
  };
}
export function putAccountStatusThunk(personAccountStatus: PersonAccountStatus) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putAccountStatusActions.start());
    return apiClient.put(`v1/person/${personAccountStatus.personId}/accountStatus`, {
      auth: true,
      data: personAccountStatus,
      successAction: processPutAccountStatusSuccess,
      failAction: processPutAccountStatusFail
    });
  };
}

export function processPutDeviceGroupSuccess(payload: WorkflowUpdatingResponse<DeviceGroup>): Function {
  return function (dispatch: Function): void {
    dispatch(putDeviceGroupActions.success(payload));
  };
}
function processPutDeviceGroupFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putDeviceGroupActions.fail(error));
  };
}
export function putDeviceGroupThunk(deviceGroup: DeviceGroup, personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putDeviceGroupActions.start());
    return apiClient.put(`v1/person/${personId}/deviceGroup`, {
      auth: true,
      params: {
        'device-group': deviceGroup
      },
      successAction: processPutDeviceGroupSuccess,
      failAction: processPutDeviceGroupFail
    });
  };
}

export function processPutSleepLogDeviceTimesEnabledSuccess(personId: string, sleepLogDeviceTimesEnabledTs?: Nullable<string>): Function {
  return function (dispatch: Function): void {
    dispatch(putSleepLogDeviceTimesEnabledActions.success({ personId, sleepLogDeviceTimesEnabledTs }));
  };
}
function processPutSleepLogDeviceTimesEnabledFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putSleepLogDeviceTimesEnabledActions.fail(error));
  };
}
export function putSleepLogDeviceTimesEnabledThunk(enabled: boolean, personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putSleepLogDeviceTimesEnabledActions.start());
    return apiClient.put('v1/person/sleepLogDeviceTimesEnabled', {
      auth: true,
      params: {
        'person-id': personId,
        enabled: enabled
      },
      successAction: (ts) => processPutSleepLogDeviceTimesEnabledSuccess(personId, ts),
      failAction: processPutSleepLogDeviceTimesEnabledFail
    });
  };
}

export function processPutEnableChatBotSuccess(personId: string, chatChannelType: ChatChannelType, chatBotEnabledTs?: Nullable<string>): Function {
  return function (dispatch: Function): void {
    dispatch(putChatBotEnabledActions.success({ personId, chatChannelType, chatBotEnabledTs }));
  };
}
function processPutEnableChatBotFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putChatBotEnabledActions.fail(error));
  };
}
export function putEnableChatBotThunk(enabled: boolean, personId: string, chatChannelType: ChatChannelType) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putChatBotEnabledActions.start());
    return apiClient.put(`v1/person/${personId}/enableChatbot`, {
      auth: true,
      params: {
        'person-id': personId,
        enabled: enabled,
        'chat-channel-type': chatChannelType
      },
      successAction: (ts: string, chatChannelType: ChatChannelType) => processPutEnableChatBotSuccess(personId, chatChannelType, ts),
      failAction: processPutEnableChatBotFail
    });
  };
}

export function processPutEnableMobileCustomSleepStoriesSuccess(personSetting: PersonSetting): Function {
  return function (dispatch: Function): void {
    dispatch(putMobileCustomSleepStoriesEnabledActions.success(personSetting));
  };
}
function processPutEnableMobileCustomSleepStoriesFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putMobileCustomSleepStoriesEnabledActions.fail(error));
  };
}

export function putEnableMobileCustomSleepStoriesThunk(enabled: boolean, personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putMobileCustomSleepStoriesEnabledActions.start());
    return apiClient.put(`v1/person/${personId}/mobileCustomSleepStoriesEnabled`, {
      auth: true,
      params: {
        enabled: enabled
      },
      successAction: (personSetting: PersonSetting) => processPutEnableMobileCustomSleepStoriesSuccess(personSetting),
      failAction: processPutEnableMobileCustomSleepStoriesFail
    });
  };
}

export function processPutShowSleepFeaturesSuccess(personSetting: PersonSetting): Function {
  return function (dispatch: Function): void {
    dispatch(putShowSleepFeaturesActions.success(personSetting));
  };
}

function processPutShowSleepFeaturesFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putShowSleepFeaturesActions.fail(error));
  };
}

export function putShowSleepFeaturesThunk(enabled: boolean, personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putShowSleepFeaturesActions.start());
    return apiClient.put(`v1/person/${personId}/showSleepFeatures`, {
      auth: true,
      params: {
        enabled: enabled
      },
      successAction: (personSetting: PersonSetting) => processPutShowSleepFeaturesSuccess(personSetting),
      failAction: processPutShowSleepFeaturesFail
    });
  };
}

export function processPutEnableDeviceTrainingSuccess(personSetting: PersonSetting): Function {
  return function (dispatch: Function): void {
    dispatch(putEnableDeviceTrainingActions.success(personSetting));
  };
}

function processPutEnableDeviceTrainingFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putEnableDeviceTrainingActions.fail(error));
  };
}

export function putEnableDeviceTrainingThunk(enabled: boolean, personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putEnableDeviceTrainingActions.start());
    return apiClient.put(`v1/person/${personId}/enableDeviceTraining`, {
      auth: true,
      params: {
        enabled: enabled
      },
      successAction: (personSetting: PersonSetting) => processPutEnableDeviceTrainingSuccess(personSetting),
      failAction: processPutEnableDeviceTrainingFail
    });
  };
}

export function processPutEnableSleepScheduleSuccess(personSetting: PersonSetting): Function {
  return function (dispatch: Function): void {
    dispatch(putSleepScheduleEnabledActions.success(personSetting));
  };
}
function processPutEnableSleepScheduleFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putSleepScheduleEnabledActions.fail(error));
  };
}

export function putEnableSleepScheduleThunk(enabled: boolean, personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putSleepScheduleEnabledActions.start());
    return apiClient.put(`v1/person/${personId}/sleepScheduleEnabled`, {
      auth: true,
      params: {
        enabled: enabled
      },
      successAction: (personSetting: PersonSetting) => processPutEnableSleepScheduleSuccess(personSetting),
      failAction: processPutEnableSleepScheduleFail
    });
  };
}

export function processPutEnableLunaSleepLogSummariesSuccess(personId: string, lunaSleepLogSummariesEnabledTs?: Nullable<string>): Function {
  return function (dispatch: Function): void {
    dispatch(putLunaSleepLogSummariesEnabledActions.success({ personId, lunaSleepLogSummariesEnabledTs }));
  };
}
function processPutEnableLunaSleepLogSummariesFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putLunaSleepLogSummariesEnabledActions.fail(error));
  };
}

export function putEnableLunaSleepLogSummariesThunk(enabled: boolean, personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putLunaSleepLogSummariesEnabledActions.start());
    return apiClient.put(`v1/person/${personId}/lunaSleepLogSummariesEnabled`, {
      auth: true,
      params: {
        enabled: enabled
      },
      successAction: (ts) => processPutEnableLunaSleepLogSummariesSuccess(personId, ts),
      failAction: processPutEnableLunaSleepLogSummariesFail
    });
  };
}

export function processPutEnableLunaSleepScheduleSummariesSuccess(personId: string, lunaSleepScheduleSummariesEnabledTs?: Nullable<string>): Function {
  return function (dispatch: Function): void {
    dispatch(putLunaSleepScheduleSummariesEnabledActions.success({ personId, lunaSleepScheduleSummariesEnabledTs }));
  };
}
function processPutEnableLunaSleepScheduleSummariesFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putLunaSleepScheduleSummariesEnabledActions.fail(error));
  };
}

export function putEnableLunaSleepScheduleSummariesThunk(enabled: boolean, personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putLunaSleepScheduleSummariesEnabledActions.start());
    return apiClient.put(`v1/person/${personId}/lunaSleepScheduleSummariesEnabled`, {
      auth: true,
      params: {
        enabled: enabled
      },
      successAction: (ts) => processPutEnableLunaSleepScheduleSummariesSuccess(personId, ts),
      failAction: processPutEnableLunaSleepScheduleSummariesFail
    });
  };
}

export function processPutEnableLunaWeeklySummariesSuccess(personId: string, lunaWeeklySummariesEnabledTs?: Nullable<string>): Function {
  return function (dispatch: Function): void {
    dispatch(putLunaWeeklySummariesEnabledActions.success({ personId, lunaWeeklySummariesEnabledTs }));
  };
}
function processPutEnableLunaWeeklySummariesFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putLunaWeeklySummariesEnabledActions.fail(error));
  };
}

export function putEnableLunaWeeklySummariesThunk(enabled: boolean, personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putLunaWeeklySummariesEnabledActions.start());
    return apiClient.put(`v1/person/${personId}/lunaWeeklySummariesEnabled`, {
      auth: true,
      params: {
        enabled: enabled
      },
      successAction: (ts) => processPutEnableLunaWeeklySummariesSuccess(personId, ts),
      failAction: processPutEnableLunaWeeklySummariesFail
    });
  };
}

export function processPutEnableSleepLogAutoSubmissionSuccess(personId: string, sleepLogAutoSubmissionEnabledTs?: Nullable<string>): Function {
  return function (dispatch: Function): void {
    dispatch(putSleepLogAutoSubmissionEnabledActions.success({ personId, sleepLogAutoSubmissionEnabledTs }));
  };
}
function processPutEnableSleepLogAutoSubmissionFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putSleepLogAutoSubmissionEnabledActions.fail(error));
  };
}

export function putEnableSleepLogAutoSubmissionThunk(enabled: boolean, personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putSleepLogAutoSubmissionEnabledActions.start());
    return apiClient.put(`v1/person/${personId}/sleepLogAutoSubmissionEnabled`, {
      auth: true,
      params: {
        enabled: enabled
      },
      successAction: (ts) => processPutEnableSleepLogAutoSubmissionSuccess(personId, ts),
      failAction: processPutEnableSleepLogAutoSubmissionFail
    });
  };
}

export function processPutEsIdSuccess(personId: string, deviceId?: Nullable<string>, esId?: Nullable<string>): Function {
  return function (dispatch: Function): void {
    dispatch(putEsIdActions.success({ personId, deviceId, esId }));
  };
}
function processPutEsIdFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putEsIdActions.fail(error));
  };
}
export function putEsIdThunk(personId: string, deviceId?: Nullable<string>, esId?: Nullable<string>) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putEsIdActions.start());
    return apiClient.put(`v1/person/${personId}/esId`, {
      auth: true,
      params: {
        'es-id': esId
      },
      successAction: () => processPutEsIdSuccess(personId, deviceId, esId),
      failAction: processPutEsIdFail
    });
  };
}

export function processSaveIsiScoresSuccess(personIsiScores: PersonIsiScore): Function {
  return function (dispatch: Function): void {
    dispatch(saveIsiScoreActions.success(personIsiScores));
  };
}
function processSaveIsiScoresFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(saveIsiScoreActions.fail(error));
  };
}
export function saveIsiScoresThunk(personIsiScores: PersonIsiScore) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(saveIsiScoreActions.start());
    return apiClient.post(`v1/person/isiScores`, {
      auth: true,
      data: personIsiScores,
      successAction: () => processSaveIsiScoresSuccess(personIsiScores),
      failAction: processSaveIsiScoresFail
    });
  };
}

export function processSaveOrderSuccess(personOrder: PersonOrder): Function {
  return function (dispatch: Function): void {
    dispatch(saveOrderActions.success(personOrder));
  };
}
function processSaveOrderFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(saveOrderActions.fail(error));
  };
}
export function saveOrderThunk(personOrder: PersonOrder) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(saveOrderActions.start());
    return apiClient.post(`v1/person/order`, {
      auth: true,
      data: personOrder,
      successAction: () => processSaveOrderSuccess(personOrder),
      failAction: processSaveOrderFail
    });
  };
}

export function processSaveShipmentSuccess(deviceShipment: DeviceShipment): Function {
  return function (dispatch: Function): void {
    dispatch(saveShipmentActions.success(deviceShipment));
  };
}
function processSaveShipmentFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(saveShipmentActions.fail(error));
  };
}
export function saveShipmentThunk(deviceShipment: DeviceShipment) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(saveShipmentActions.start());
    return apiClient.post(`v1/person/shipment`, {
      auth: true,
      data: deviceShipment,
      successAction: () => processSaveShipmentSuccess(deviceShipment),
      failAction: processSaveShipmentFail
    });
  };
}

function processGetValidSleepSchedulesSuccess(payload: SleepSchedule[]) {
  return function (dispatch: Function) {
    dispatch(getValidSleepSchedulesActions.success(payload));
  };
}

function processGetValidSleepSchedulesFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(getValidSleepSchedulesActions.fail(payload));
  };
}

export function getValidSleepSchedulesThunk(personId: string, startTs: number, endTs: number) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getValidSleepSchedulesActions.start());
    return apiClient.get(`v1/person/${personId}/validSleepSchedulesInRange`, {
      params: {
        'start-ts': startTs,
        'end-ts': endTs
      },
      auth: true,
      successAction: processGetValidSleepSchedulesSuccess,
      failAction: processGetValidSleepSchedulesFail
    });
  };
}

function processGetPersonPermissionsByEmailSuccess(payload: PersonPermissionsResponse) {
  return function (dispatch: Function) {
    dispatch(getPersonPermissionsByEmailActions.success(payload));
  };
}

function processGetPersonPermissionsByEmailFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(getPersonPermissionsByEmailActions.fail(payload));
  };
}

export function getPersonPermissionsByEmailThunk(email: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getPersonPermissionsByEmailActions.start());
    return apiClient.get(`v1/person/personPermissionsByEmail`, {
      params: {
        email: email
      },
      auth: true,
      successAction: processGetPersonPermissionsByEmailSuccess,
      failAction: processGetPersonPermissionsByEmailFail
    });
  };
}

function processGetPersonPermissionsSuccess(payload: PersonPermissionsResponse[]) {
  return function (dispatch: Function) {
    dispatch(getPersonPermissionsActions.success(payload));
  };
}

function processGetPersonPermissionsFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(getPersonPermissionsActions.fail(payload));
  };
}

export function getPersonPermissionsThunk(includeNoPerms?: Nullable<boolean>) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getPersonPermissionsActions.start());
    return apiClient.get(`v1/person/allPersonPermissions`, {
      params: {
        'include-no-perms': includeNoPerms
      },
      auth: true,
      successAction: processGetPersonPermissionsSuccess,
      failAction: processGetPersonPermissionsFail
    });
  };
}

export function processGetPersonPermissionsByOrgIdSuccess(payload: Person[]) {
  return function (dispatch: Function) {
    dispatch(getPersonAndPermissionsByOrgIdAction.success(payload));
  };
}

export function processGetPersonPermissionsByOrgIdFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(getPersonAndPermissionsByOrgIdAction.fail(payload));
  };
}

export function getPersonPermissionsByOrgIdThunk(orgId: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getPersonAndPermissionsByOrgIdAction.start());
    return apiClient.get(`v1/person/${orgId}/personPermissions`, {
      auth: true,
      successAction: processGetPersonPermissionsByOrgIdSuccess,
      failAction: processGetPersonPermissionsByOrgIdFail
    });
  };
}

function processRemovePersonPermissionRoleSuccess(payload: PersonPermissionRole) {
  return function (dispatch: Function) {
    dispatch(removePersonPermissionRoleActions.success(payload));
  };
}

function processRemovePersonPermissionRoleFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(removePersonPermissionRoleActions.fail(payload));
  };
}

export function removePersonPermissionRoleThunk(personPermissionRoleId: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(removePersonPermissionRoleActions.start());
    return apiClient.delete(`v1/person/personPermissionRole/${personPermissionRoleId}`, {
      auth: true,
      successAction: processRemovePersonPermissionRoleSuccess,
      failAction: processRemovePersonPermissionRoleFail
    });
  };
}

function processPutPersonPermissionRoleSuccess(payload: PersonPermissionRole) {
  return function (dispatch: Function) {
    dispatch(putPersonPermissionRoleActions.success(payload));
  };
}

function processPutPersonPermissionRoleFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(putPersonPermissionRoleActions.fail(payload));
  };
}

export function putPersonPermissionRoleThunk(personId: string, permissionRoleId: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(putPersonPermissionRoleActions.start());
    return apiClient.post(`v1/person/${personId}/permissionRole/${permissionRoleId}`, {
      auth: true,
      successAction: processPutPersonPermissionRoleSuccess,
      failAction: processPutPersonPermissionRoleFail
    });
  };
}

function processGetAllPermissionRolesSuccess(payload: PermissionRole[]) {
  return function (dispatch: Function) {
    dispatch(getAllPermissionRolesActions.success(payload));
  };
}

function processGetAllPermissionRolesFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(getAllPermissionRolesActions.fail(payload));
  };
}

export function getAllPermissionRolesThunk() {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getAllPermissionRolesActions.start());
    return apiClient.get(`v1/person/permissionRoles/all`, {
      auth: true,
      successAction: processGetAllPermissionRolesSuccess,
      failAction: processGetAllPermissionRolesFail
    });
  };
}

function processGetPersonVitalsSuccess(payload: PersonVitals[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getPersonVitalsAction.success(payload));
  };
}

function processGetPersonVitalsFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getPersonVitalsAction.fail(error));
  };
}

export function getPersonVitalsThunk(organizationId: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getPersonVitalsAction.start());
    return apiClient.get(`v1/person/listPersonVitalDashboardData/${organizationId}`, {
      auth: true,
      successAction: processGetPersonVitalsSuccess,
      failAction: processGetPersonVitalsFail
    });
  };
}
