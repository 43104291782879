import { CareTagStatus } from '../../components/TagStatus/TagStatus';
import { getCarePlanDeviceContentByIdSelector } from '../../redux/content/contentSelectors';
import { DeviceContent, SurveyOptionRange } from '../../redux/content/contentTypes';
import { getDeviceTimeZoneIdSelector } from '../../redux/device/deviceSelectors';
import { getTriggerTitle, getQuestionIconType, isActivityPastDue, isActivityExpired, isActivitySkipped, isActivityCompleted } from '../../utils/carePlanUtils';
import { getCarePlanDateUTC } from '../../utils/time';
import { ReduxState } from '../types';
import { CareFeedItem, CarePlan, CarePlanStep, CarePlanUnit, Measurement, MetricIDs, MetricUnit, PersonWithCarePlans, RenderedCareFeedItem, Trigger } from './carePlanTypes';

export const getCarePlanStateSelector = (state: ReduxState) => {
  return state.carePlan;
};

export const getCarePlanByIdSelector = (state: ReduxState): Record<string, CarePlan> => {
  return getCarePlanStateSelector(state)?.carePlanById;
};

export const getCarePlanStepByIdSelector = (state: ReduxState): Record<string, CarePlanStep> => {
  return getCarePlanStateSelector(state)?.carePlanStepById;
};

export const getCarePlanUnitByIdSelector = (state: ReduxState): Record<string, CarePlanUnit> => {
  return getCarePlanStateSelector(state)?.carePlanUnitById;
};

export const getTriggerByIdSelector = (state: ReduxState): Record<string, Trigger> => {
  return getCarePlanStateSelector(state)?.triggerById;
};

export const getMetricUnitSelector = (state: ReduxState): Record<string, MetricUnit> => {
  return getCarePlanStateSelector(state)?.metricUnitById;
};

export const getSurveyRangeByMetricIdSelector = (personId: string, metricId: MetricIDs, state: ReduxState): SurveyOptionRange | undefined => {
  const grouppedMeasurements = getGrouppedMeasurementsSelector(state);
  const measurementsByDateUTCMs = (grouppedMeasurements && personId && grouppedMeasurements[personId]) || {};
  let surveyRange: SurveyOptionRange | undefined;
  for (let dateUTCMs in measurementsByDateUTCMs) {
    const measurements = measurementsByDateUTCMs[dateUTCMs];
    const metricMeasurements = measurements?.[metricId];
    if (!metricMeasurements || metricMeasurements.length === 0) continue;
    const latestMeasurement: Measurement = metricMeasurements[0];
    const currentSurveyRange = latestMeasurement?.surveyResponse?.surveyQuestion?.surveyOptions?.[0]?.surveyOptionRange;
    if (currentSurveyRange) {
      surveyRange = currentSurveyRange; // latest available survey range
    }
  }

  return surveyRange;
};

export const getMetricNameByIdSelector = (personId: string, metricId: MetricIDs, state: ReduxState): string | undefined => {
  const measurementsByPersonId = getMeasurementsByPersonIdSelector(state);
  const metricUnitById = getMetricUnitSelector(state);
  const metricUnitId = personId && measurementsByPersonId && measurementsByPersonId[personId] && measurementsByPersonId[personId][metricId][0]?.unitId;
  return metricUnitId ? metricUnitById[metricUnitId].name : undefined;
};

export const getPersonWithCarePlansByIdSelector = (state: ReduxState): Record<string, PersonWithCarePlans> => {
  return getCarePlanStateSelector(state)?.personWithCarePlansById;
};

export const getMeasurementsByPersonIdSelector = (state: ReduxState): Record<string, Measurement[]> | undefined => {
  return getCarePlanStateSelector(state)?.measurementsByPersonId;
};

// [personId][nightDateUTCMs]: [{ metricId: [...measurementsSortedByDate] }]
export const getGrouppedMeasurementsSelector = (state: ReduxState): Record<string, Record<string, Record<MetricIDs, Measurement[]>>> => {
  const measurementsByPersonId = getMeasurementsByPersonIdSelector(state);
  const grouppedMeasurements: Record<string, Record<string, Record<MetricIDs, Measurement[]>>> = {};
  measurementsByPersonId &&
    Object.keys(measurementsByPersonId)?.forEach((personId) => {
      const measurements = measurementsByPersonId[personId];
      const timeZoneId = getDeviceTimeZoneIdSelector(personId, state);
      const grouped = measurements.reduce((acc, measurement) => {
        const carePlanDateUTCMs = getCarePlanDateUTC(measurement.created, timeZoneId)?.toMillis();
        const metricId = measurement.metricId;
        if (!acc[carePlanDateUTCMs]) {
          acc[carePlanDateUTCMs] = {};
        }
        if (!acc[carePlanDateUTCMs][metricId]) {
          acc[carePlanDateUTCMs][metricId] = [measurement];
        } else {
          // add with sort:
          let inserted = false;
          for (let i = 0; i < acc[carePlanDateUTCMs][metricId].length; i++) {
            if (measurement.created > acc[carePlanDateUTCMs][metricId][i].created) {
              acc[carePlanDateUTCMs][metricId].splice(i, 0, measurement);
              inserted = true;
              break;
            }
          }
          if (!inserted) {
            // If the loop completes without inserting, the new item is the oldest
            acc[carePlanDateUTCMs][metricId].push(measurement);
          }
        }
        return acc;
      }, {});
      grouppedMeasurements[personId] = grouped;
    });
  return grouppedMeasurements;
};

export const getLatestNightDateUTCMsOfMeasurements = (personId: string, state: ReduxState): string | undefined => {
  const grouppedMeasurements = getGrouppedMeasurementsSelector(state)[personId];
  const dates = grouppedMeasurements && Object.keys(grouppedMeasurements).sort((dateMidnightUTCMsA, dateMidnightUTCMsB) => Number(dateMidnightUTCMsB) - Number(dateMidnightUTCMsA));
  return dates?.length ? dates[0] : undefined;
};

export const getSelectedPersonCareFeedItemsByDateSelector = (state: ReduxState): Record<string, CareFeedItem[]> => {
  return getCarePlanStateSelector(state)?.selectedPersonCareFeedItemsByDate;
};

export const getUpcomingCareFeedItemsSelector = (startUtcMs: number, timeZoneId: string, state: ReduxState): CareFeedItem[] => {
  const careFeedItemsByDate = getSelectedPersonCareFeedItemsByDateSelector(state);
  const careFeedItems = careFeedItemsByDate[startUtcMs];
  const deviceContentById = getCarePlanDeviceContentByIdSelector(state);
  return (
    careFeedItems?.filter((careFeedItem: CareFeedItem) => {
      const deviceContentId = careFeedItem?.deviceContentId;
      const deviceContent = deviceContentId ? deviceContentById[deviceContentId] : undefined;
      // only not pastDue, not expired, not skipped and not completed
      return (
        !isActivityPastDue(careFeedItem, timeZoneId) && !isActivityExpired(careFeedItem, timeZoneId) && !isActivitySkipped(deviceContent) && !isActivityCompleted(careFeedItem)
      );
    }) || []
  );
};

export const getPastCareFeedItemsSelector = (startUtcMs: number, timeZoneId: string, state: ReduxState): CareFeedItem[] => {
  const careFeedItemsByDate = getSelectedPersonCareFeedItemsByDateSelector(state);
  const careFeedItems = careFeedItemsByDate[startUtcMs];
  const deviceContentById = getCarePlanDeviceContentByIdSelector(state);

  return (
    careFeedItems?.filter((careFeedItem: CareFeedItem) => {
      const deviceContentId = careFeedItem?.deviceContentId;
      const deviceContent = deviceContentId ? deviceContentById[deviceContentId] : undefined;
      // only pastDue, expired, skipped or completed
      return isActivityPastDue(careFeedItem, timeZoneId) || isActivityExpired(careFeedItem, timeZoneId) || isActivitySkipped(deviceContent) || isActivityCompleted(careFeedItem);
    }) || []
  );
};

export const getRenderedCareFeedItemsSelector = (startUtcMs: number, isUpcoming: boolean, timeZoneId: string, state: ReduxState): RenderedCareFeedItem[] => {
  const careFeedItems = isUpcoming ? getUpcomingCareFeedItemsSelector(startUtcMs, timeZoneId, state) : getPastCareFeedItemsSelector(startUtcMs, timeZoneId, state);
  const triggerById = getTriggerByIdSelector(state);
  const deviceContentById = getCarePlanDeviceContentByIdSelector(state);
  const renderedCareFeedItems: RenderedCareFeedItem[] = [];
  let prevTriggerTitle;
  for (let i = 0; i < careFeedItems.length; i++) {
    const careFeedItem = careFeedItems[i];
    const triggerId = careFeedItem?.triggerId;
    const afterTime = careFeedItem?.afterTs;
    if (!triggerId || !afterTime) {
      continue;
    }
    const trigger = triggerById[triggerId];
    const triggerTitle = getTriggerTitle(afterTime, trigger.id, timeZoneId);
    const deviceContentId = careFeedItem?.deviceContentId;
    const deviceContent = deviceContentId ? deviceContentById[deviceContentId] : undefined;
    const deviceContentPages = deviceContent ? deviceContent?.deviceContentPages : [];
    let status: CareTagStatus | undefined;
    if (isActivityCompleted(careFeedItem)) {
      status = 'done';
    } else if (isActivitySkipped(deviceContent)) {
      status = 'skipped';
    } else if (isActivityExpired(careFeedItem, timeZoneId) || isActivityPastDue(careFeedItem, timeZoneId)) {
      status = 'no-response';
    }
    let attachedTriggerTitle;
    if (prevTriggerTitle !== triggerTitle) {
      attachedTriggerTitle = triggerTitle;
      prevTriggerTitle = triggerTitle;
    }
    if (deviceContentPages?.length) {
      for (let j = 0; j < deviceContentPages.length; j++) {
        const question = deviceContentPages[j]?.surveyQuestion?.questionText || deviceContentPages[j]?.longText;
        const metric = deviceContentPages[j]?.surveyQuestion?.metric;
        const questionLabel = metric?.name;
        renderedCareFeedItems.push({
          id: careFeedItem.id,
          question,
          questionLabel,
          quesitonIconType: getQuestionIconType(deviceContentPages[j], deviceContent?.deviceContentType),
          triggerTitle: attachedTriggerTitle,
          status,
          hideConnector: i === careFeedItems.length - 1 && j === deviceContentPages.length - 1,
          hideDialog: !isUpcoming
        });
      }
    } else {
      const question = deviceContent && deviceContent?.contentText;
      renderedCareFeedItems.push({
        id: careFeedItem.id,
        question,
        quesitonIconType: getQuestionIconType(undefined, deviceContent?.deviceContentType),
        triggerTitle: attachedTriggerTitle,
        status,
        hideConnector: i === careFeedItems.length - 1,
        hideDialog: !isUpcoming
      });
    }
  }

  return renderedCareFeedItems;
};

export const isLoadingCareFeedItemsSelector = (state: ReduxState): boolean => {
  return getCarePlanStateSelector(state)?.isLoadingCareFeedItems;
};

export const isDeletingCareFeedItemSelector = (state: ReduxState): boolean => {
  return getCarePlanStateSelector(state)?.isDeletingCareFeedItem;
};

export const getCarePlanActivitiesByIdSelector = (state: ReduxState): Record<string, DeviceContent> => {
  return getCarePlanStateSelector(state)?.carePlanActivityById;
};
