import React from 'react';
import style from './PatientTable.scss';
import TableCell from './TableCell';
import TableHeader from './TableHeader';
import { PatientTableColName, PatientTableProps } from './PatientTableTypes';

const Table: React.FC<PatientTableProps> = React.memo(({ patientTableData, onPressTableRow }) => {
  return (
    <table className={style.patientsTable}>
      <thead>
        <tr className={style.tableRow}>
          <TableHeader>Name</TableHeader>
          <TableHeader>Heart rate (bpm)</TableHeader>
          <TableHeader>Breathing rate (bpm)</TableHeader>
          <TableHeader>Sleep duration</TableHeader>
          <TableHeader>Sleep Efficiency</TableHeader>
          <TableHeader>Patient presence</TableHeader>
          <TableHeader>Device status</TableHeader>
          <TableHeader>Last reading</TableHeader>
        </tr>
      </thead>
      <tbody>
        {patientTableData.map((patientData) => {
          const { personId, name, heartRate, breathingRate, sleepDurations, sleepEfficiency, personPresence, deviceStatus, avatar, lastReading } = patientData;
          const handleRowClick = () => {
            onPressTableRow(personId);
          };
          return (
            <tr key={personId} onClick={handleRowClick} className={style.tableRow}>
              <TableCell colName={PatientTableColName.NAME} value={name} avatarUrl={avatar} />
              <TableCell colName={PatientTableColName.HEART_RATE} value={heartRate} />
              <TableCell colName={PatientTableColName.BREATHING_RATE} value={breathingRate} />
              <TableCell colName={PatientTableColName.SLEEP_DURATION} value={sleepDurations} />
              <TableCell colName={PatientTableColName.SLEEP_EFFICENCY} value={sleepEfficiency} />
              <TableCell colName={PatientTableColName.PERSON_PRESENCE} value={personPresence} modifiedDate={lastReading} />
              <TableCell colName={PatientTableColName.DEVICE_STATUS} value={deviceStatus} />
              <TableCell colName={PatientTableColName.LAST_READING} value={lastReading} modifiedDate={lastReading} />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});

export default Table;
