import React from 'react';
import { RouteObject } from 'react-router-dom';
import ChangePassword from '../containers/Account/containers/ChangePassword/ChangePassword';
import ForgotPassword from '../containers/Account/containers/ForgotPassword/ForgotPassword';
import InviteAccount from '../containers/Account/containers/InviteAccount/InviteAccount';
import AllContent from '../containers/AllContent/AllContent';
import AllFlags from '../containers/AllFlags/AllFlags';
import AllPatients from '../containers/AllPatients/AllPatients';
import AllTracks from '../containers/AllTracks/AllTracks';
import AssignCarePlan from '../containers/AssignCarePlan/AssignCarePlan';
import CareGroup from '../containers/CareGroup/CareGroup';
import CareTeam from '../containers/CareTeam/CareTeam';
import ConfirmationPage from '../containers/ConfirmationPage/ConfirmationPage';
import ContentManager from '../containers/ContentManager/ContentManager';
import Dashboard from '../containers/Dashboard/Dashboard';
import DeleteAccount from '../containers/DeleteAccount/DeleteAccount';
import DeleteAccountConfirm from '../containers/DeleteAccountConfirm/DeleteAccountConfirm';
import DemoAccessCodes from '../containers/DemoAccessCodes/DemoAccessCodes';
import DeviceContentDetails from '../containers/DeviceContentDetails/DeviceContentDetails';
import DeviceOverview from '../containers/DeviceOverview/DeviceOverview';
import DeviceProvisioningOverview from '../containers/DeviceProvisioningOverview/DeviceProvisioningOverview';
import FirmwareVersionOverview from '../containers/FirmwareVersionOverview/FirmwareVersionOverview';
import FlagDetails from '../containers/FlagDetails/FlagDetails';
import Home from '../containers/Home/Home';
import KokoMap from '../containers/KokoMap/KokoMap';
import LoginWrapper from '../containers/Login/LoginWrapper';
import ManageGroupPlans from '../containers/ManageGroupPlans/ManageGroupPlans';
import NewPatient from '../containers/NewPatient/NewPatient';
import NewTeamMember from '../containers/NewTeamMember/NewTeamMember';
import PageDetails from '../containers/PageDetails/PageDetails';
import PatientProfile from '../containers/PatientProfile/PatientProfile';
// import PersonPermissions from '../containers/PersonPermissions/PersonPermissions';
import AdminOrganizations from '../containers/AdminOrganizations/AdminOrganizations';
import AdminPermissionRoles from '../containers/AdminPermissionRoles/AdminPermissionRoles';
import AdminUserPermissions from '../containers/AdminUserPermissions/AdminUserPermissions';
import NewActivity from '../containers/NewActivity/NewActivity';
import PersonProgram from '../containers/PersonProgram/PersonProgram';
import SleepScheduleReview from '../containers/SleepScheduleReview/SleepScheduleReview';
import Track from '../containers/Track/Track';
import UnreadSleepCoachMessages from '../containers/UnreadSleepCoachMessages/UnreadSleepCoachMessages';
import UserDetails from '../containers/UserDetails/UserDetails';
import UserOverview from '../containers/UserOverview/UserOverview';
import Users from '../containers/Users/Users';
import withAnalyticsPageEvents from '../containers/withAnalyticsPageEvents/withAnalyticsPageEvents';
import { PageType } from '../redux/analytics/analyticsTypes';
import { RouteNames } from '../utils/navigationUtils';
import AdminSideBar from './AdminSideBar/AdminSideBar';
import NavSideBar from './NavSideBar/NavSideBar';
import ProtectedRoute from './ProtectedRoute';
import RedirectIfLoggedInRoute from './RedirectIfLoggedInRoute';
import RouteNotFound from './RouteNotFound';

const LoginPage = withAnalyticsPageEvents(PageType.LOGIN)(LoginWrapper);
const ForgotPasswordPage = withAnalyticsPageEvents(PageType.FORGOT_PASSWORD)(ForgotPassword);
const ResetPasswordPage = withAnalyticsPageEvents(PageType.RESET_PASSWORD_BY_TOKEN)(ChangePassword);
const InviteAccountPage = withAnalyticsPageEvents(PageType.RESET_PASSWORD_BY_TOKEN)(InviteAccount);
const DeleteAccountPage = withAnalyticsPageEvents(PageType.DELETE_ACCOUNT)(DeleteAccount);
const DeleteAccountConfirmPage = withAnalyticsPageEvents(PageType.DELETE_ACCOUNT_CONFIRM)(DeleteAccountConfirm);

const MainMenuPage = withAnalyticsPageEvents(PageType.MAIN_MENU)(Home);
const KokoMapPage = withAnalyticsPageEvents(PageType.KOKO_MAP)(KokoMap);
const UsersPage = withAnalyticsPageEvents(PageType.USERS)(Users);
const AllUsersPage = withAnalyticsPageEvents(PageType.ALL_USERS)(UserOverview);
const FlaggedUsersPage = withAnalyticsPageEvents(PageType.FLAGGED_USERS)(UserOverview);
const UserDetailsPage = withAnalyticsPageEvents(PageType.USER_DETAILS)(UserDetails);
const UserDetailsSleepCoachChatPage = withAnalyticsPageEvents(PageType.USER_DETAILS_SLEEP_COACH_CHAT)(UserDetails);
const DemoAccessCodesPage = withAnalyticsPageEvents(PageType.DEMO_ACCESS_CODES)(DemoAccessCodes);
const DeviceProvisioningPage = withAnalyticsPageEvents(PageType.DEVICE_PROVISIONING)(DeviceProvisioningOverview);
const FirmwareVersionsPage = withAnalyticsPageEvents(PageType.FIRMWARE_VERSIONS)(FirmwareVersionOverview);
const DeviceOverviewPage = withAnalyticsPageEvents(PageType.DEVICE_OVERVIEW)(DeviceOverview);

const EmailConfirmationTokenPage = withAnalyticsPageEvents(PageType.EMAIL_CONFIRMATION_TOKEN)(ConfirmationPage);
const ContentManagerPage = withAnalyticsPageEvents(PageType.CONTENT_MANAGER)(ContentManager);
const ContentAllTracksPage = withAnalyticsPageEvents(PageType.CONTENT_ALL_TRACKS)(AllTracks);
const ContentAllContentPage = withAnalyticsPageEvents(PageType.CONTENT_ALL_CONTENT)(AllContent);
const ContentTrackPage = withAnalyticsPageEvents(PageType.CONTENT_TRACK)(Track);
const ContentDeviceContentDetailsPage = withAnalyticsPageEvents(PageType.CONTENT_DEVICE_CONTENT_DETAILS)(DeviceContentDetails);
const ContentPageDetailsUpdatePage = withAnalyticsPageEvents(PageType.CONTENT_PAGE_DETAILS_UPDATE)(PageDetails);
const ContentPageDetailsAddPage = withAnalyticsPageEvents(PageType.CONTENT_PAGE_DETAILS_ADD)(PageDetails);
const FlagDetailsPage = withAnalyticsPageEvents(PageType.FLAG_DETAILS)(FlagDetails);
const AllFlagsPage = withAnalyticsPageEvents(PageType.ALL_FLAGS)(AllFlags);
const NewSleepCoachChatsPage = withAnalyticsPageEvents(PageType.NEW_SLEEP_COACH_CHATS)(UnreadSleepCoachMessages);
const SleepSchedulesReviewPage = withAnalyticsPageEvents(PageType.SLEEP_SCHEDULE_REVIEW)(SleepScheduleReview);

const MainDashboardPage = withAnalyticsPageEvents(PageType.NEW_DASHBOARD)(Dashboard);
const AllPatientsPage = withAnalyticsPageEvents(PageType.ALL_PATIENTS)(AllPatients);
const PatientProfilePage = withAnalyticsPageEvents(PageType.PATIENT_PROFILE)(PatientProfile);
const NewPatientPage = withAnalyticsPageEvents(PageType.NEW_PATIENT)(NewPatient);
const NewTeamMemberPage = withAnalyticsPageEvents(PageType.NEW_TEAM_MEMBER)(NewTeamMember);
const AssignCarePlanPage = withAnalyticsPageEvents(PageType.ASSIGN_CARE_PLAN)(AssignCarePlan);
const NewActivityPage = withAnalyticsPageEvents(PageType.NEW_ACTIVITY)(NewActivity);
const CareTeamPage = withAnalyticsPageEvents(PageType.CARE_TEAM)(CareTeam);
const CareGroupPage = withAnalyticsPageEvents(PageType.CARE_GROUP)(CareGroup);
const ManageGroupPlansPage = withAnalyticsPageEvents(PageType.MANAGE_GROUP_PLANS)(ManageGroupPlans);
const AdminOrganizationsPage = withAnalyticsPageEvents(PageType.ADMIN_ORGANIZATIONS)(AdminOrganizations);
const AdminUsersPermissionsPage = withAnalyticsPageEvents(PageType.ADMIN_USERS)(AdminUserPermissions);
const AdminPermissionRolesPage = withAnalyticsPageEvents(PageType.ADMIN_PERMISSION_ROLES)(AdminPermissionRoles);

export const routesForPublic: RouteObject[] = [
  { path: '/emailConfirmation/:token?', Component: EmailConfirmationTokenPage },
  { path: RouteNames.FORGOT_PASSWORD, Component: ForgotPasswordPage },
  { path: RouteNames.RESET_PASSWORD, Component: ResetPasswordPage },
  { path: RouteNames.INVITE, Component: InviteAccountPage },
  { path: RouteNames.DELETE_ACCOUNT, Component: DeleteAccountPage },
  { path: RouteNames.DELETE_ACCOUNT_CONFIRM, Component: DeleteAccountConfirmPage }
];

export const routesForNotAuthenticatedOnly: RouteObject[] = [
  {
    element: <RedirectIfLoggedInRoute />, // Wrap the component in RedirectIfLoggedInRoute
    children: [
      { path: RouteNames.LOGIN, Component: LoginPage },
      { path: '*', Component: RouteNotFound }
    ]
  }
];

export const routesForAuthenticatedOnly: RouteObject[] = [
  {
    path: RouteNames.HOME,
    element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
    children: [
      { path: RouteNames.HOME, Component: MainMenuPage },
      { path: RouteNames.KOKO_MAP, Component: KokoMapPage },
      { path: RouteNames.DEVICE_OVERVIEW, Component: DeviceOverviewPage },
      // { path: RouteNames.PERSON_PERMISSIONS, Component: PersonPermissionsPage },
      { path: RouteNames.PERSON_PROGRAM, Component: PersonProgram },
      { path: RouteNames.USERS, Component: UsersPage },
      { path: RouteNames.ALL_USERS, Component: AllUsersPage },
      { path: RouteNames.FLAGGED_USERS, Component: FlaggedUsersPage },
      { path: RouteNames.USER_DETAILS, Component: UserDetailsPage },
      { path: RouteNames.USER_DETAILS_SLEEP_COACH_CHAT, Component: UserDetailsSleepCoachChatPage },
      { path: RouteNames.DEMO_ACCESS_CODES, Component: DemoAccessCodesPage },
      { path: RouteNames.DEVICE_PROVISIONING, Component: DeviceProvisioningPage },
      { path: RouteNames.FIRMWARE_VERSIONS, Component: FirmwareVersionsPage },
      { path: RouteNames.CONTENT_MANAGER, Component: ContentManagerPage },
      { path: RouteNames.ALL_TRACKS, Component: ContentAllTracksPage },
      { path: RouteNames.ALL_CONTENT, Component: ContentAllContentPage },
      { path: RouteNames.TRACK, Component: ContentTrackPage },
      { path: RouteNames.DEVICE_CONTENT_DETAILS, Component: ContentDeviceContentDetailsPage },
      { path: RouteNames.PAGE_DETAILS_UPDATE, Component: ContentPageDetailsUpdatePage },
      { path: RouteNames.PAGE_DETAILS_ADD, Component: ContentPageDetailsAddPage },
      { path: RouteNames.FLAG_DETAILS, Component: FlagDetailsPage },
      { path: RouteNames.ALL_FLAGS, Component: AllFlagsPage },
      { path: RouteNames.NEW_SLEEP_COACH_CHATS, Component: NewSleepCoachChatsPage },
      { path: RouteNames.SLEEP_SCHEDULE_REVIEW, Component: SleepSchedulesReviewPage },
      { path: RouteNames.SLEEP_SCHEDULE_REVIEW_DETAILS, Component: SleepSchedulesReviewPage },
      { path: '*', Component: LoginPage }
    ]
  }
];

export const routesForAuthenticatedOnlyNewDashboard: RouteObject[] = [
  {
    path: RouteNames.HOME,
    element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
    children: [
      {
        path: RouteNames.HOME,
        element: <NavSideBar />,
        children: [
          {
            // When the path is '/' show AllPatientsPage
            index: true,
            Component: AllPatientsPage
          },
          // { path: RouteNames.DASHBOARD, Component: MainDashboardPage },
          { path: RouteNames.ALL_PATIENTS, Component: AllPatientsPage },
          // { path: RouteNames.PATIENT_DETAILS, Component: PatientProfileOldPage },
          { path: RouteNames.PATIENT_DETAILS, Component: PatientProfilePage },
          { path: RouteNames.NEW_PATIENT, Component: NewPatientPage },
          { path: RouteNames.ASSIGN_CARE_PLAN, Component: AssignCarePlanPage },
          { path: RouteNames.NEW_ACTIVITY, Component: NewActivityPage },
          { path: RouteNames.CARE_TEAM, Component: CareTeamPage },
          { path: RouteNames.CARE_GROUP, Component: CareGroupPage },
          { path: RouteNames.MANAGE_CARE_PLANS, Component: ManageGroupPlansPage },
          { path: RouteNames.NEW_TEAM_MEMBER, Component: NewTeamMemberPage }
        ]
      },
      {
        path: RouteNames.ADMIN,
        element: <AdminSideBar />,
        children: [
          {
            // When the path is '/' show AllPatientsPage
            index: true,
            Component: AdminOrganizationsPage
          },
          { path: RouteNames.ADMIN_ORGANIZATIONS, Component: AdminOrganizationsPage },
          { path: RouteNames.ADMIN_USERS, Component: AdminUsersPermissionsPage },
          { path: RouteNames.ADMIN_PERMISSION_ROLES, Component: AdminPermissionRolesPage }
        ]
      },
      // old routes below:
      { path: RouteNames.HOME_OLD, Component: MainMenuPage },
      { path: RouteNames.KOKO_MAP, Component: KokoMapPage },
      { path: RouteNames.DEVICE_OVERVIEW, Component: DeviceOverviewPage },
      // { path: RouteNames.PERSON_PERMISSIONS, Component: PersonPermissionsPage },
      { path: RouteNames.PERSON_PROGRAM, Component: PersonProgram },
      { path: RouteNames.USERS, Component: UsersPage },
      { path: RouteNames.ALL_USERS, Component: AllUsersPage },
      { path: RouteNames.FLAGGED_USERS, Component: FlaggedUsersPage },
      { path: RouteNames.USER_DETAILS, Component: UserDetailsPage },
      { path: RouteNames.USER_DETAILS_SLEEP_COACH_CHAT, Component: UserDetailsSleepCoachChatPage },
      { path: RouteNames.DEMO_ACCESS_CODES, Component: DemoAccessCodesPage },
      { path: RouteNames.DEVICE_PROVISIONING, Component: DeviceProvisioningPage },
      { path: RouteNames.FIRMWARE_VERSIONS, Component: FirmwareVersionsPage },
      { path: RouteNames.CONTENT_MANAGER, Component: ContentManagerPage },
      { path: RouteNames.ALL_TRACKS, Component: ContentAllTracksPage },
      { path: RouteNames.ALL_CONTENT, Component: ContentAllContentPage },
      { path: RouteNames.TRACK, Component: ContentTrackPage },
      { path: RouteNames.DEVICE_CONTENT_DETAILS, Component: ContentDeviceContentDetailsPage },
      { path: RouteNames.PAGE_DETAILS_UPDATE, Component: ContentPageDetailsUpdatePage },
      { path: RouteNames.PAGE_DETAILS_ADD, Component: ContentPageDetailsAddPage },
      { path: RouteNames.FLAG_DETAILS, Component: FlagDetailsPage },
      { path: RouteNames.ALL_FLAGS, Component: AllFlagsPage },
      { path: RouteNames.NEW_SLEEP_COACH_CHATS, Component: NewSleepCoachChatsPage },
      { path: RouteNames.SLEEP_SCHEDULE_REVIEW, Component: SleepSchedulesReviewPage },
      { path: RouteNames.SLEEP_SCHEDULE_REVIEW_DETAILS, Component: SleepSchedulesReviewPage },
      { path: '*', Component: LoginPage }
    ]
  }
];
