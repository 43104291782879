import React from 'react';
import { Text } from '../../../../components/Typography';
import style from './TableCell.scss';
import { getInitials } from '../../../../utils/util';
import { PatientTableColName, PatientTableRangeByColName } from './PatientTableTypes';
import { getElapsedTimeDisplay } from 'utils/time';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { TextVariant } from '../../../../components/TextField/TextField';
import { IconButton } from '@material-ui/core';
import exclaimationOctagon from '../../../../assets/images/exclaimationOctagon.png';
import yellowOctagon from '../../../../assets/images/yellowOctagon.png';
import darkGreenDot from '../../../../assets/images/darkGreenDot.png';
import grayDot from '../../../../assets/images/grayDot.png';

type CellPropT = string | number;

export type PatientTableCellProp<T extends CellPropT> = {
  value?: T;
  colName: PatientTableColName;
  avatarUrl?: string;
  modifiedDate?: string;
};

enum PatientPresenceType {
  NEAR_THE_DEVICE = 'Near the device',
  OUT_OF_LUNA_VISION = "Out of Luna's vision"
}

const getPatientPresence = (patientPresence: string): PatientPresenceType => {
  const nearDevicePresences = ['BED', 'BEDSIDE_NEAR', 'BEDSIDE_FAR', 'BEDROOM'];
  return nearDevicePresences.includes(patientPresence) ? PatientPresenceType.NEAR_THE_DEVICE : PatientPresenceType.OUT_OF_LUNA_VISION;
};

const getDisplayValueOrDefault = (value: CellPropT | undefined, defaultValue = 'na', postfix = ''): string => {
  if (value === null || value === undefined) {
    return defaultValue;
  }
  if (typeof value === 'string') {
    return value + postfix;
  }
  if (typeof value === 'number' && !isNaN(value)) {
    return '' + value + postfix;
  }
  return defaultValue;
};

const getValueTextElement = (value: string, cellTextStyle = style.cellText, variant: TextVariant = 'sm') => {
  return (
    <Text variant={variant} className={cellTextStyle}>
      {value}
    </Text>
  );
};

const getDeviceStatusStyleClassName = (value: string) => {
  const capitalized = value.toUpperCase();
  if (capitalized === 'ACTIVE') return { divStyle: style.deviceStatusActive, textStyle: style.cellTextDeviceStatusActive };
  if (capitalized === 'OFFLINE') return { divStyle: style.deviceStatusOffline, textStyle: style.cellTextDeviceStatusOffline };
  if (capitalized === 'ERROR') return { divStyle: style.deviceStatusError, textStyle: style.cellTextDeviceStatusError };
  return { divStyle: style.deviceStatusOffline, textStyle: style.cellTextDeviceStatusOffline };
};

function getCell<T extends CellPropT>({ value, colName, avatarUrl, modifiedDate }: PatientTableCellProp<T>) {
  const strValue = '' + value;

  const range = PatientTableRangeByColName[colName];
  const isOutOfRange = typeof value === 'number' && range ? value < range.min || value > range.max : false;

  switch (colName) {
    case PatientTableColName.NAME:
      return (
        <>
          <div className={style.avatar}>{avatarUrl ? <img src={avatarUrl} /> : getInitials(strValue)}</div>
          {getValueTextElement(strValue)}
        </>
      );
    case PatientTableColName.HEART_RATE:
      return (
        <>
          {isOutOfRange && <img className={style.octagonImg} src={exclaimationOctagon} />}
          {getValueTextElement(getDisplayValueOrDefault(value))}
        </>
      );
    case PatientTableColName.SLEEP_DURATION: {
      return (
        <>
          {isOutOfRange && <img className={style.octagonImg} src={yellowOctagon} />}
          {getValueTextElement(getDisplayValueOrDefault(value))}
        </>
      );
    }
    case PatientTableColName.DEVICE_STATUS: {
      const { divStyle, textStyle } = getDeviceStatusStyleClassName(strValue);
      return <div className={divStyle}>{getValueTextElement(getDisplayValueOrDefault(value, 'unknown'), textStyle)}</div>;
    }
    case PatientTableColName.LAST_READING: {
      const lastReading = modifiedDate ? getElapsedTimeDisplay(modifiedDate) : '';
      return (
        <>
          {getValueTextElement(lastReading, style.cellText, 'xs')}
          {/* TODO: uncomment below when we adding these supports later */}
          {/* <IconButton className={style.iconButton}>
            <MoreVertIcon />
          </IconButton>
          <IconButton className={style.iconButton}>
            <NavigateNextIcon />
          </IconButton> */}
        </>
      );
    }
    case PatientTableColName.PERSON_PRESENCE: {
      const patientPresence = getPatientPresence(strValue);
      const timePresence = modifiedDate ? getElapsedTimeDisplay(modifiedDate) : '';
      return (
        <div>
          <div className={style.patientPresenceStatus}>
            <img className={style.dotImg} src={patientPresence === PatientPresenceType.NEAR_THE_DEVICE ? darkGreenDot : grayDot} />
            {getValueTextElement(patientPresence)}
          </div>
          <div className={style.patientPresenceTime}>{getValueTextElement(timePresence, style.cellText, 'xs')}</div>
        </div>
      );
    }
    case PatientTableColName.SLEEP_EFFICENCY:
      return <>{getValueTextElement(getDisplayValueOrDefault(value, 'na', '%'))}</>;
    case PatientTableColName.BREATHING_RATE:
    // fall through
    default:
      return <>{getValueTextElement(getDisplayValueOrDefault(value))}</>;
  }
}

const TableCell = <T extends CellPropT>(cellProps: PatientTableCellProp<T>) => {
  return (
    <td className={style.tableCell}>
      <div className={style.cellBox}>{getCell(cellProps)}</div>
    </td>
  );
};

export default TableCell;
