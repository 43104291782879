import { PersonVitals } from 'redux/person/personTypes';

export enum PatientTableColName {
  NAME = 'name',
  HEART_RATE = 'heart rate',
  BREATHING_RATE = 'breathing rate',
  SLEEP_DURATION = 'sleep duration',
  SLEEP_EFFICENCY = 'sleep efficiency',
  PERSON_PRESENCE = 'patient presence',
  DEVICE_STATUS = 'device status',
  LAST_READING = 'last reading'
}

export type PatientTableColDataRangeType = {
  min: number;
  max: number;
};

export const PatientTableRangeByColName: Record<PatientTableColName, PatientTableColDataRangeType | null> = {
  [PatientTableColName.NAME]: null,
  [PatientTableColName.HEART_RATE]: { min: 50, max: 120 },
  [PatientTableColName.BREATHING_RATE]: { min: 10, max: 30 },
  [PatientTableColName.SLEEP_DURATION]: { min: 6 * 60, max: 12 * 60 },
  [PatientTableColName.SLEEP_EFFICENCY]: { min: 50, max: 100 },
  [PatientTableColName.PERSON_PRESENCE]: null,
  [PatientTableColName.DEVICE_STATUS]: null,
  [PatientTableColName.LAST_READING]: null
};

export type PatientTableData = PersonVitals & {
  name: string;
  avatar?: string;
};

export interface PatientTableProps extends React.ComponentPropsWithoutRef<'table'> {
  patientTableData: PatientTableData[];
  onPressTableRow: (personId: string) => void;
}
