import { GEAR_IMAGE_URL, HAND_HEART_IMAGE_URL, USERS_IMAGE_URL } from 'assets/images/images';
import React from 'react';
import { Menu, MenuItem, MenuItemStyles, Sidebar } from 'react-pro-sidebar';
import { ConnectedProps, connect } from 'react-redux';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import chartLineIcon from '../../assets/images/chartLine.png';
import clipboardTextIcon from '../../assets/images/clipboardText.png';
import { useAppDispatch } from '../../hooks';
import { getAuthPersonIdSelector } from '../../redux/oauth/oauthSelectors';
import { logoutThunk } from '../../redux/oauth/oauthThunks';
import { getOrganizationByIdSelector } from '../../redux/organization/organizationSelectors';
import {
  getPersonByIdSelector,
  hasAuthUserAdminPermissionSelector,
  hasManageCareTeamPermissionSelector,
  hasPatientAssignPermissionSelector,
  hasPatientClinicalDataInCareTeamPhiReadPermissionSelector,
  hasTeamMemberAccountsCreatePermissionSelector
} from '../../redux/person/personSelector';
import { ReduxState } from '../../redux/types';
import { Color } from '../../utils/color';
import { RouteNames } from '../../utils/navigationUtils';
import style from './NavSideBar.scss';
import BrandingHeader from './components/BrandingHeader';
import SignOut from './components/SignOut';
import { getPersonPermissionsThunk } from '../../redux/person/personThunks';

const SIDEBAR_WIDTH = 300;
export const NAV_BROKEN_ENABLED = false;

const menuItemStyles: MenuItemStyles = {
  root: {
    fontFamily: 'Outfit',
    fontSize: '16px',
    fontWeight: 400,
    color: Color.GRAY_BLUE
  },
  button: {
    transition: 'background-color 0.1s ease-out',
    padding: '8px 12px',
    margin: '0px 16px',
    borderRadius: '6px',
    gap: '12px',
    '&:hover': {
      backgroundColor: Color.WHITE,
      opacity: 0.5
    },
    '&.ps-active': {
      backgroundColor: Color.GRAY_50,
      color: Color.GRAY_BLUE,
      fontWeight: 400
    }
  },
  icon: {
    margin: '0px',
    padding: '0px',
    width: '20px',
    minWidth: '20px',
    height: '20px',
    lineHeight: '20px'
  }
};

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

export const NavSideBar: React.FC<PropsFromRedux> = ({
  authPersonId,
  personById,
  organizationById,
  hasTeamMemberAccountsCreatePermission,
  hasManageCareTeamPermission,
  hasPatientAssignPermission,
  hasPatientClinicalDataInCareTeamPhiReadPermission,
  hasAuthUserAdminPermission
}) => {
  const [toggled, setToggled] = React.useState<boolean>(false);
  const [broken, setBroken] = React.useState<boolean>(false);
  const authPerson = personById[authPersonId || ''];

  const organization = authPerson?.organizationId ? organizationById[authPerson?.organizationId] : undefined;

  const location = useLocation();
  const dispatch = useAppDispatch();

  const isActive = (paths) => paths.some((path) => location.pathname.match(`^${path}`));

  const handleLoggout = () => {
    dispatch(logoutThunk());
  };

  return (
    <div className={style.container}>
      <Sidebar
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        breakPoint={NAV_BROKEN_ENABLED ? 'md' : undefined}
        backgroundColor={Color.WHITE}
        width={`${SIDEBAR_WIDTH}px`}
        rootStyles={{
          borderRightColor: Color.GRAY_100
        }}
      >
        <div className={style.sideBarContainer}>
          <BrandingHeader logoImageUrl={organization?.logoImageUrl} />
          <div className={style.topMenuContainer}>
            <Menu menuItemStyles={menuItemStyles}>
              {/* <MenuItem
                component={<Link to={RouteNames.DASHBOARD} />}
                active={location.pathname === RouteNames.HOME || isActive([RouteNames.DASHBOARD])}
                icon={<img src={chartLineIcon} className={style.menuIcon} />}
              >
                Dashboard
              </MenuItem> */}
              {hasPatientClinicalDataInCareTeamPhiReadPermission && (
                <MenuItem
                  component={<Link to={RouteNames.ALL_PATIENTS} />}
                  active={isActive([RouteNames.ALL_PATIENTS, RouteNames.PATIENT_DETAILS])}
                  icon={<img src={HAND_HEART_IMAGE_URL} className={style.menuIcon} />}
                >
                  Patients
                </MenuItem>
              )}
              {hasTeamMemberAccountsCreatePermission && (
                <MenuItem
                  component={<Link to={RouteNames.NEW_TEAM_MEMBER} />}
                  active={isActive([RouteNames.NEW_TEAM_MEMBER])}
                  icon={<img src={USERS_IMAGE_URL} className={style.menuIcon} />}
                >
                  New team member
                </MenuItem>
              )}
              {hasManageCareTeamPermission && (
                <MenuItem
                  component={<Link to={RouteNames.MANAGE_CARE_PLANS} />}
                  active={isActive([RouteNames.MANAGE_CARE_PLANS])}
                  icon={<img src={clipboardTextIcon} className={style.menuIcon} />}
                >
                  Care plans
                </MenuItem>
              )}
              {hasPatientAssignPermission && (
                <MenuItem
                  component={<Link to={RouteNames.ASSIGN_CARE_PLAN} />}
                  active={isActive([RouteNames.ASSIGN_CARE_PLAN])}
                  icon={<img src={clipboardTextIcon} className={style.menuIcon} />}
                >
                  Assign care plan
                </MenuItem>
              )}
              {/* <MenuItem
                component={<Link to={RouteNames.CARE_GROUP} />}
                active={isActive([RouteNames.CARE_GROUP])}
                icon={<img src={STETHOSCOPE_IMAGE_URL} className={style.menuIcon} />}
              >
                Care group
              </MenuItem> */}
              {hasManageCareTeamPermission && (
                <MenuItem component={<Link to={RouteNames.CARE_TEAM} />} active={isActive([RouteNames.CARE_TEAM])} icon={<img src={USERS_IMAGE_URL} className={style.menuIcon} />}>
                  Care team
                </MenuItem>
              )}
            </Menu>
          </div>
          {hasAuthUserAdminPermission && (
            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem component={<Link to={RouteNames.ADMIN} />} icon={<img src={GEAR_IMAGE_URL} className={style.menuIcon} />}>
                Admin Settings
              </MenuItem>
            </Menu>
          )}
          {/* <Menu menuItemStyles={menuItemStyles}>
            <MenuItem icon={<img src={GEAR_IMAGE_URL} className={style.menuIcon} />}>Settings</MenuItem>
          </Menu> */}
          <SignOut avatarUrl="" personFullName={authPerson?.fullName || ''} onClick={handleLoggout} />
        </div>
      </Sidebar>

      <div className={style.contentContainer}>
        <Outlet context={[broken, toggled, setToggled]} />
      </div>
    </div>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      authPersonId: getAuthPersonIdSelector(state),
      personById: getPersonByIdSelector(state),
      organizationById: getOrganizationByIdSelector(state),
      hasTeamMemberAccountsCreatePermission: hasTeamMemberAccountsCreatePermissionSelector(state),
      hasManageCareTeamPermission: hasManageCareTeamPermissionSelector(state),
      hasPatientAssignPermission: hasPatientAssignPermissionSelector(state),
      hasPatientClinicalDataInCareTeamPhiReadPermission: hasPatientClinicalDataInCareTeamPhiReadPermissionSelector(state),
      hasAuthUserAdminPermission: hasAuthUserAdminPermissionSelector(state)
    };
  },
  (dispatch: Function) => ({})
);

export default compose(connectRedux)(NavSideBar) as React.ComponentType;
