import { createAction } from '@reduxjs/toolkit';
import { DeviceShipment } from '../device/deviceTypes';
import { createReduxActionCreator } from '../types';
import {
  DeviceGroup,
  PermissionRole,
  // PermissionRoleUpsertResponsePayload,
  Person,
  PersonAccountStatus,
  PersonDetailsResponse,
  PersonIsiScore,
  PersonOrder,
  PersonOverviewResponse,
  PersonPermissionRole,
  PersonPermissionsResponse,
  PersonSetting,
  PersonVitals,
  PutChatBotEnabledResponsePayload,
  PutEsIdResponsePayload,
  PutLunaSleepLogSummariesEnabledResponsePayload,
  PutLunaSleepScheduleSummariesEnabledResponsePayload,
  PutLunaWeeklySummariesEnabledResponsePayload,
  PutSleepLogAutoSubmissionEnabledResponsePayload,
  PutSleepLogDeviceTimesEnabledResponsePayload,
  SleepSchedule,
  SleepScheduleCalculation,
  SleepScheduleCalculationResponse,
  WorkflowUpdatingResponse
} from './personTypes';

// Action Prefixes
export const PERSON_ACTION_PREFIX = 'person-redux/';

export const getPersonByIdActions = createReduxActionCreator<Person>(`${PERSON_ACTION_PREFIX}get-person`);
export const postNewPatientAction = createReduxActionCreator<Person>(`${PERSON_ACTION_PREFIX}post-new-patient`);
export const postNewTeamMemberAction = createReduxActionCreator<Person>(`${PERSON_ACTION_PREFIX}post-new-team-member`);
export const userPermissionsActions = createReduxActionCreator<PermissionRole[]>(`${PERSON_ACTION_PREFIX}user-permissions`);
// export const permissionRoleUpsertActions = createReduxActionCreator<PermissionRoleUpsertResponsePayload>(`${PERSON_ACTION_PREFIX}permission-role-upsert`);
export const updatePersonProgramStepActions = createReduxActionCreator<undefined>(`${PERSON_ACTION_PREFIX}update-person-program-step`);
export const getPersonSettingsActions = createReduxActionCreator<PersonSetting>(`${PERSON_ACTION_PREFIX}get-person-settings`);
export const updatePersonSettingsActions = createReduxActionCreator<PersonSetting>(`${PERSON_ACTION_PREFIX}update-person-settings`);
export const getSleepSchedulesActions = createReduxActionCreator<SleepSchedule[]>(`${PERSON_ACTION_PREFIX}get-sleep-schedules`);
export const updateSleepScheduleActions = createReduxActionCreator<SleepSchedule>(`${PERSON_ACTION_PREFIX}update-sleep-schedule`);
export const sendPersonPushNotificationActions = createReduxActionCreator<undefined>(`${PERSON_ACTION_PREFIX}send-person-push-notification`);
export const sendPushNotificationsActions = createReduxActionCreator<undefined>(`${PERSON_ACTION_PREFIX}send-push-notifications`);
export const calculateSleepScheduleActions = createReduxActionCreator<SleepScheduleCalculation>(`${PERSON_ACTION_PREFIX}calculate-sleep-schedule`);
export const saveSleepScheduleAndCalculationsActions = createReduxActionCreator<SleepScheduleCalculationResponse>(`${PERSON_ACTION_PREFIX}save-sleep-schedule-and-calculations`);
export const getPersonOverviewActions = createReduxActionCreator<Array<PersonOverviewResponse>, boolean>(`${PERSON_ACTION_PREFIX}get-person-overview`);
export const getPersonOverviewCountActions = createReduxActionCreator<number>(`${PERSON_ACTION_PREFIX}get-person-overview-count`);
export const getPersonDetailsActions = createReduxActionCreator<PersonDetailsResponse>(`${PERSON_ACTION_PREFIX}get-person-details`);
export const putAccountStatusActions = createReduxActionCreator<WorkflowUpdatingResponse<PersonAccountStatus>>(`${PERSON_ACTION_PREFIX}put-account-status`);
export const putDeviceGroupActions = createReduxActionCreator<WorkflowUpdatingResponse<DeviceGroup>>(`${PERSON_ACTION_PREFIX}put-device-group`);
export const putSleepLogDeviceTimesEnabledActions = createReduxActionCreator<PutSleepLogDeviceTimesEnabledResponsePayload>(
  `${PERSON_ACTION_PREFIX}put-sleep-log-device-times-enabled`
);
export const putLunaSleepLogSummariesEnabledActions = createReduxActionCreator<PutLunaSleepLogSummariesEnabledResponsePayload>(
  `${PERSON_ACTION_PREFIX}put-luna-sleep-log-summaries-enabled`
);
export const putLunaSleepScheduleSummariesEnabledActions = createReduxActionCreator<PutLunaSleepScheduleSummariesEnabledResponsePayload>(
  `${PERSON_ACTION_PREFIX}put-luna-sleep-schedule-summaries-enabled`
);
export const putLunaWeeklySummariesEnabledActions = createReduxActionCreator<PutLunaWeeklySummariesEnabledResponsePayload>(
  `${PERSON_ACTION_PREFIX}put-luna-weekly-summaries-enabled`
);
export const putChatBotEnabledActions = createReduxActionCreator<PutChatBotEnabledResponsePayload>(`${PERSON_ACTION_PREFIX}put-chat-bot-enabled`);
export const putMobileCustomSleepStoriesEnabledActions = createReduxActionCreator<PersonSetting>(`${PERSON_ACTION_PREFIX}put-mobile-custom-sleep-stories-enabled`);
export const putSleepScheduleEnabledActions = createReduxActionCreator<PersonSetting>(`${PERSON_ACTION_PREFIX}put-sleep-schedule-enabled`);
export const putSleepLogAutoSubmissionEnabledActions = createReduxActionCreator<PutSleepLogAutoSubmissionEnabledResponsePayload>(
  `${PERSON_ACTION_PREFIX}put-sleep-log-auto-submission-enabled`
);
export const putShowSleepFeaturesActions = createReduxActionCreator<PersonSetting>(`${PERSON_ACTION_PREFIX}put-show-sleep-features`);
export const putEnableDeviceTrainingActions = createReduxActionCreator<PersonSetting>(`${PERSON_ACTION_PREFIX}put-enable-device-training`);
export const putEsIdActions = createReduxActionCreator<PutEsIdResponsePayload>(`${PERSON_ACTION_PREFIX}put-es-id`);
export const saveIsiScoreActions = createReduxActionCreator<PersonIsiScore>(`${PERSON_ACTION_PREFIX}save-isi-score`);
export const saveOrderActions = createReduxActionCreator<PersonOrder>(`${PERSON_ACTION_PREFIX}save-order`);
export const saveShipmentActions = createReduxActionCreator<DeviceShipment>(`${PERSON_ACTION_PREFIX}save-shipment`);
export const getValidSleepSchedulesActions = createReduxActionCreator<SleepSchedule[]>(`${PERSON_ACTION_PREFIX}get-valid-sleep-schedules`);
export const getPersonPermissionsByEmailActions = createReduxActionCreator<PersonPermissionsResponse>(`${PERSON_ACTION_PREFIX}get-person-permissions-by-email`);
export const getPersonPermissionsActions = createReduxActionCreator<PersonPermissionsResponse[]>(`${PERSON_ACTION_PREFIX}get-person-permissions`);
export const getPersonSettingsProgramByEmailActions = createReduxActionCreator<Person>(`${PERSON_ACTION_PREFIX}get-person-settings-program-byemail`);
export const getPersonAndPermissionsByOrgIdAction = createReduxActionCreator<Person[]>(`${PERSON_ACTION_PREFIX}get-person-and-permissions-by-org-id`);
// new Actions for removing personPermissionRole and adding personPermissionRole
export const removePersonPermissionRoleActions = createReduxActionCreator<PersonPermissionRole>(`${PERSON_ACTION_PREFIX}remove-person-permission-role`);
export const putPersonPermissionRoleActions = createReduxActionCreator<PersonPermissionRole>(`${PERSON_ACTION_PREFIX}put-person-permission-role`);
export const getAllPermissionRolesActions = createReduxActionCreator<PermissionRole[]>(`${PERSON_ACTION_PREFIX}get-all-permission-roles`);

export const clearPersonResponseStatusAction = createAction<undefined>(`${PERSON_ACTION_PREFIX}clear-person-response-status`);
export const clearUpdatePersonProgramAction = createAction<undefined>(`${PERSON_ACTION_PREFIX}clear-update-person-program`);
export const clearSleepCalculationAction = createAction<Nullable<boolean>>(`${PERSON_ACTION_PREFIX}clear-sleep-calculation`);

export const getPersonVitalsAction = createReduxActionCreator<PersonVitals[]>(`${PERSON_ACTION_PREFIX}get-person-vitals`);
