import { GridFilterItem, GridSortItem } from '@mui/x-data-grid';
import { ChatChannelType } from 'redux/chat/chatTypes';
import { ClientDevice, ClientDevicePushInfo } from '../clientDevice/clientDeviceTypes';
import { Error } from '../common/types';
import { PersonWorkflow, ProgramSubType, ProgramType, SurveyScoreResponse } from '../content/contentTypes';
import { Device, DeviceRemoteCommand, DeviceShipment, DeviceStateEntry, Location, SensorDevice } from '../device/deviceTypes';

export const PERMISSION_ROLE_ID_AGENT: string = '00000000-0000-0000-0000-000000000001';
export const PERMISSION_ROLE_ID_CONTENT_EDITOR: string = '00000000-0000-0000-0000-000000000002';
export const PERMISSION_ROLE_ID_OPERATIONAL: string = '00000000-0000-0000-0000-000000000003';
export const PERMISSION_ROLE_ID_PII_DATA: string = '00000000-0000-0000-0000-000000000004';
export const PERMISSION_ROLE_ID_ADMIN: string = '00000000-0000-0000-0000-000000000005';
export const PERMISSION_ROLE_ID_ORG_ADMIN: string = '00000000-0000-0000-0000-000000000006';
export const PERMISSION_ROLE_ID_MD: string = '00000000-0000-0000-0000-000000000007';
export const PERMISSION_ROLE_ID_RN: string = '00000000-0000-0000-0000-000000000008';
export const PERMISSION_ROLE_ID_NP: string = '00000000-0000-0000-0000-00000000009';
export const PERMISSION_ROLE_ID_CARE_NAVIGATOR: string = '00000000-0000-0000-0000-00000000000a';
export const PERMISSION_ROLE_ID_PATIENT: string = '00000000-0000-0000-0000-00000000000b';

export interface PersonState {
  readonly isLoading: Record<string, boolean>;
  isLoadingUserPermissions: boolean;
  readonly error: Record<string, Error>;
  readonly success: Record<string, boolean>;
  readonly personById: Record<string, Person>;
  readonly personSettingById: Record<string, PersonSetting>;
  readonly personOverviewById: Record<string, PersonOverview>;
  readonly sensorPersonById: Record<string, SensorPerson>;
  readonly devicePersonByPersonId: Record<string, Device>;
  readonly personSleepProgramById: Record<string, PersonSleepProgram>;
  readonly clientDevicePushInfoById: Record<string, ClientDevicePushInfo>;
  readonly personOrderById: Record<string, PersonOrder>;
  readonly personIsiScoreById: Record<string, PersonIsiScore>;
  readonly personAccountStatusById: Record<string, PersonAccountStatus>;
  readonly deviceTrainingById: Record<string, DeviceTraining>;
  readonly deviceShipmentById: Record<string, DeviceShipment>;
  readonly clientDeviceById: Record<string, ClientDevice>;
  readonly sleepScheduleById: Record<string, SleepSchedule>;
  readonly personPermissionsById: Record<string, PersonActivePermissions>;
  readonly permissionRoleById: Record<string, PermissionRole>;
  readonly personPermissionRoleById: Record<string, PersonPermissionRole>;
  readonly personPermissionRoleIdsByPersonId: Record<string, string[]>;
  readonly permissionRoleTypeById: Record<string, PermissionRoleType>;
  readonly personVitalsByPersonId: Record<string, PersonVitals>;
  lastLoadedPersonId?: Nullable<string>;
  updatePersonProgramLoading?: Nullable<boolean>;
  updatePersonProgramError?: Nullable<string>;
  isPersonSettingProgramLoading: boolean;
  personSettingProgramError?: Nullable<string>;
  isSendPersonPushNotificationLoading: boolean;
  sendPersonPushNotificationError?: Nullable<string>;
  isUpdatePersonSettingsLoading: boolean;
  updatePersonSettingsError?: Nullable<string>;
  isUpdateSleepScheduleLoading: boolean;
  updateSleepScheduleError?: Nullable<string>;
  historicSleepSchedules?: Nullable<SleepSchedule[]>;
  currentSleepScheduleCalculation?: Nullable<SleepScheduleCalculation>;
  readonly personOverviewList: Array<string>;
  selectedPersonDetails?: Nullable<PersonDetails>;
  personOverviewCount: number;
  lastUserOverviewInitialLoad: number;
  snsError?: Nullable<string>;
  adHocError?: Nullable<string>;
  batchLoading: boolean;
  batchSuccess?: Nullable<boolean>;
  pageLoading: boolean;
  userPermissions?: Nullable<PermissionRole[]>;
  kokoMapSleepScheduleIds: string[];
  successMessage?: Nullable<string>;
  warningMessage?: Nullable<string>;
  loadingEventChange: boolean;
  isLoadingPostNewPatient: boolean;
  errorPostNewPatient?: Error;
  isLoadingPostNewTeamMember: boolean;
  errorPostNewTeamMember?: Error;
  personVitalsError?: Nullable<string>;
}

export interface Person {
  id: string;
  organizationId: string;
  email?: Nullable<string>;
  givenNames?: Nullable<string>;
  familyName?: Nullable<string>;
  birthDate?: Nullable<string>;
  phoneNumber?: Nullable<string>;
  phoneNumberVerified?: Nullable<boolean>;
  secondaryPhoneNumber?: Nullable<string>;
  emailVerificationDate?: Nullable<string>;
  accountCreatedDate?: Nullable<string>;
  taCAgreedTs?: Nullable<string>;
  mobilePolicyAgreedDate?: Nullable<string>;
  personType?: Nullable<PersonType>;
  freshdeskAccountId?: Nullable<string>;
  deletedTs?: Nullable<string>;
  accountStatus?: Nullable<AccountStatus>;
  healthSafetyAgreedTs?: Nullable<string>;
  esId?: Nullable<string>;
  fullName?: Nullable<string>;
  currentWindDownWeek?: Nullable<number>;
  currentBedResetStep?: Nullable<number>;
  currentCbtiProgramStep?: Nullable<number>;
  currentCbtiHybridProgramStep?: Nullable<number>;
  currentManualSleepLogProgramStep?: Nullable<number>;
  currentSleepSchedule?: Nullable<SleepSchedule>;
  personSettings?: Nullable<PersonSetting>;
  personPermissionRoles?: Nullable<PersonPermissionRole[]>;
  latestSleepLogSleepTime?: Nullable<string>;
  latestSleepLogWakeTime?: Nullable<string>;
  created: string;
  modifiedDate: string;
  lastUpdated?: Nullable<number>;
}

export interface PersonOverviewResponse {
  person: Person;
  personSetting: PersonSetting;
  sensorPerson?: Nullable<SensorPerson>;
  sensorDevice?: Nullable<SensorDevice>;
  device?: Nullable<Device>;
  deviceRemoteCommand?: Nullable<DeviceRemoteCommand>;
  location?: Nullable<Location>;
  personProgram?: Nullable<PersonSleepProgram>;
  clientDevicePushInfo?: Nullable<ClientDevicePushInfo>;
  latestPersonOrder?: Nullable<PersonOrder>;
  latestDeviceShipment?: Nullable<DeviceShipment>;
  latestClientDevice?: Nullable<ClientDevice>;
}

export interface PersonDetailsResponse extends PersonOverviewResponse {
  latestWindDownProgram?: Nullable<PersonSleepProgram>;
  latestBedResetProgram?: Nullable<PersonSleepProgram>;
  deviceOverviewResponseEntry?: Nullable<DeviceStateEntry>;
  personIsiScores?: Nullable<PersonIsiScore[]>;
  sensorPersons?: Nullable<SensorPerson[]>;
  sensorDevices?: Nullable<SensorDevice[]>;
  devices?: Nullable<Device[]>;
  locations?: Nullable<Location[]>;
  deviceShipments?: Nullable<DeviceShipment[]>;
  personAccountStatuses?: Nullable<PersonAccountStatus[]>;
  deviceTrainings?: Nullable<DeviceTraining[]>;
}

export interface PersonDetails extends PersonOverview {
  latestWindDownProgramId?: Nullable<string>;
  latestBedResetProgramId?: Nullable<string>;
  personIsiScoreIds?: Nullable<Nullable<string>[]>;
  sensorPersonIds?: Nullable<string[]>;
  sensorDeviceIds?: Nullable<string[]>;
  deviceIds?: Nullable<string[]>;
  locationIds?: Nullable<Nullable<string>[]>;
  deviceShipmentIds?: Nullable<string[]>;
  personAccountStatusIds?: Nullable<Nullable<string>[]>;
  deviceTrainingIds?: Nullable<Nullable<string>[]>;
}

export interface PersonOverview {
  personId: string;
  sensorPersonId?: Nullable<string>;
  sensorDeviceId?: Nullable<string>;
  deviceId?: Nullable<string>;
  locationId?: Nullable<string>;
  personSleepProgramId?: Nullable<string>;
  clientDevicePushInfoId?: Nullable<string>;
  latestPersonOrderId?: Nullable<string>;
  latestDeviceShipmentId?: Nullable<string>;
  latestClientDeviceId?: Nullable<string>;
}

export interface PersonPermissionsResponse {
  person: Person;
  agent: PersonPermissionRole;
  contentEditor: PersonPermissionRole;
  operational: PersonPermissionRole;
  piiAccess: PersonPermissionRole;
  admin: PersonPermissionRole;
  hasExpiredPermissions: boolean;
}

export interface PersonActivePermissions {
  personId: string;
  agent: PersonPermissionRole;
  contentEditor: PersonPermissionRole;
  operational: PersonPermissionRole;
  piiAccess: PersonPermissionRole;
  admin: PersonPermissionRole;
  hasExpiredPermissions: boolean;
}

export interface PermissionRoleType {
  id: string;
  organizationId: string;
  name: string;
  description?: Nullable<string>;
  created: string;
  modified: string;
}

export interface PersonPermissionRole {
  id: string;
  personId: string;
  permissionRoleId: string;
  permissionRole?: Nullable<PermissionRole>;
  startTs: string;
  endTs?: Nullable<string>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface PermissionRole {
  id: string;
  permissionRoleType?: Nullable<PermissionRoleType>;
  permissionRoleTypeId: string;
  deviceMetadataReadAccess?: Nullable<boolean>;
  deviceMetadataWriteAccess?: Nullable<boolean>;
  sensorDataReadAccess?: Nullable<boolean>;
  sensorDataWriteAccess?: Nullable<boolean>;
  userDataReadAccess?: Nullable<boolean>;
  userDataWriteAccess?: Nullable<boolean>;
  piiDataReadAccess?: Nullable<boolean>;
  piiDataWriteAccess?: Nullable<boolean>;
  contentManagementReadAccess?: Nullable<boolean>;
  contentManagementWriteAccess?: Nullable<boolean>;
  clinicalAccountsCreateDisable?: Nullable<boolean>;
  nonClinicalAccountsCreateDisable?: Nullable<boolean>;
  patientAccountsCreateDisable?: Nullable<boolean>;
  orgCreateDisable?: Nullable<boolean>;
  orgAdminAccountsCreateDisable?: Nullable<boolean>;
  manageCareTeams?: Nullable<boolean>;
  patientCareTeamAssignUnassign?: Nullable<boolean>;
  patientProfileDataWritePii?: Nullable<boolean>;
  patientProfileDataReadPii?: Nullable<boolean>;
  patientClinicalDataPhiWrite?: Nullable<boolean>;
  patientClinicalDataInCareTeamPhiRead?: Nullable<boolean>;
  patientClinicalDataOutsideCareTeamPhiRead?: Nullable<boolean>;
  patientCarePlanAssignUnassign?: Nullable<boolean>;
  lunaDeviceOrgAssign?: Nullable<boolean>;
  lunaDevicePatientAssignUnassign?: Nullable<boolean>;
  thirdPartyConnectDevicesPatientAssignUnassign?: Nullable<boolean>;
  anonymousAggregatedReportsRead?: Nullable<boolean>;
  created: string;
  modified: string;
}

export interface SensorPerson {
  id: string;
  organizationId: string;
  sensorId: string;
  personId: string;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface PersonSleepProgram {
  id: string;
  personId: string;
  organizationId: string;
  programType: ProgramType;
  programSubType?: Nullable<ProgramSubType>;
  programStep?: Nullable<number>;
  onboardingDateTs?: Nullable<string>;
  endDate?: Nullable<string>;
  daysSinceOnboarding?: Nullable<number>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface PersonSetting {
  id: string;
  organizationId: string;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
  testCbtiContentDurationSeconds?: Nullable<number>;
  cohortId?: Nullable<number>;
  deviceGroup?: Nullable<DeviceGroup>;
  debugEnabled?: Nullable<boolean>;
  dataUsageAgreementType?: Nullable<DataUsageAgreementType>;
  irbConsentTs?: Nullable<string>;
  irbHipaaConsentTs?: Nullable<string>;
  sleepLogDeviceTimesEnabledTs?: Nullable<string>;
  lunaSleepLogSummariesEnabledTs?: Nullable<string>;
  lunaSleepScheduleSummariesEnabledTs?: Nullable<string>;
  lunaWeeklySummariesEnabledTs?: Nullable<string>;
  mobileChatBotEnabledTs?: Nullable<string>;
  deviceChatBotEnabledTs?: Nullable<string>;
  newSleepCoachEnabledTs?: Nullable<string>;
  contentReminders?: Nullable<boolean>;
  sleepLogReminders?: Nullable<boolean>;
  coachMessageReminders?: Nullable<boolean>;
  mobileCustomSleepStoriesEnabledTs?: Nullable<string>;
  mobileSleepCoachEnabledTs?: Nullable<string>;
  accessCompletedContentEnabledTs?: Nullable<string>;
  showSleepFeatures?: Nullable<boolean>;
  enableDeviceTraining?: Nullable<boolean>;
  freeTrialAdsEnabledTs?: Nullable<string>;
  sleepScheduleEnabledTs?: Nullable<string>;
  sleepLogAutoSubmissionEnabledTs?: Nullable<string>;
}

export interface SleepSchedule {
  id: string;
  personId: string;
  organizationId: string;
  sleepScheduleCalculationId?: Nullable<string>;
  wakeTime: string;
  sleepTime: string;
  description?: Nullable<string>;
  lunaSummary?: Nullable<string>;
  lunaRequestMessage?: Nullable<string>;
  createdByUser?: Nullable<boolean>;
  startDate?: Nullable<string>;
  endDate?: Nullable<string>;
  wakeAlarmTime?: Nullable<string>;
  wakeAlarmSkipTs?: Nullable<string>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
  sleepScheduleCalculation?: Nullable<SleepScheduleCalculation>;
  alarmEnabled?: Nullable<boolean>;
  validationWarning?: Nullable<string>;
}

export interface SleepScheduleCalculation {
  id: string;
  personId?: Nullable<string>;
  organizationId?: string;
  calcForDate?: Nullable<string>;
  lunaSummary?: Nullable<string>;
  lunaRequestMessage?: Nullable<string>;
  avgBedTime?: Nullable<string>;
  avgTryToSleepTime?: Nullable<string>;
  avgFallAsleepMs?: Nullable<number>;
  avgWakeUpDurationMs?: Nullable<number>;
  avgWakeTime?: Nullable<string>;
  avgEarlyWakeDurationMs?: Nullable<number>;
  avgOutOfBedTime?: Nullable<string>;
  avgInBedMs?: Nullable<number>;
  avgTotalSleepMs?: Nullable<number>;
  avgSleepEfficiency?: Nullable<number>;
  avgWakeUpCount?: Nullable<number>;
  surveyScore?: Nullable<number>;
  proposedSleepTime?: Nullable<string>;
  proposedWakeTime?: Nullable<string>;
  surveyScoreResponse?: Nullable<SurveyScoreResponse>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface SleepScheduleCalculationRequest {
  sleepScheduleCalculation: SleepScheduleCalculation;
  sleepSchedule: SleepSchedule;
  ignoreWarnings: boolean;
}

export interface SleepScheduleCalculationResponse {
  sleepScheduleCalculation: SleepScheduleCalculation;
  ignoreWarnings: boolean;
  sleepScheduleUpdateResponse: WorkflowUpdatingResponse<SleepSchedule>;
}

export interface PersonOrder {
  id: string;
  personId: string;
  purchaseTs?: Nullable<string>;
  daysSincePurchased?: Nullable<number>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface PersonIsiScore {
  id: string;
  personId: string;
  isiScore: number;
  isiTs: string;
  isiScoreType: IsiScoreType;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface PersonAccountStatus {
  id: string;
  personId: string;
  accountStatus: AccountStatus;
  startDate: string;
  endDate?: Nullable<string>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface WorkflowUpdatingResponse<T> {
  personId: string;
  updatedValue: T;
  updatedWorkflows: PersonWorkflow[];
}

export interface DeviceTraining {
  id: string;
  deviceId: string;
  personId: string;
  trainingTs: string;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface SleepCalculationRequest {
  surveyScore?: Nullable<number>;
  preferredWakeTime?: Nullable<string>;
  saveCalculation?: Nullable<boolean>;
  sendPush?: Nullable<boolean>;
  usingLatestSchedule?: Nullable<boolean>;
}

export interface PersonOverviewFilters {
  nonTextFilters?: Nullable<GridFilterItem[]>;
  textFilters?: Nullable<GridFilterItem[]>;
  flagId?: Nullable<GridFilterItem>;
  flagTaskStatus?: Nullable<GridFilterItem>;
  matchAnyText?: Nullable<boolean>;
  gridSortItem?: Nullable<GridSortItem>;
  limit?: Nullable<number>;
  offset?: Nullable<number>;
}

export enum PersonType {
  UNKNOWN = 'UNKNOWN',
  HUMAN = 'HUMAN',
  BOT = 'BOT'
}

export enum FlagTaskStatus {
  UNCOMPLETED = 'Task(s) Uncompleted',
  COMPLETED = 'All Task(s) Completed'
}

export enum LocationType {
  GENERIC = 'GENERIC',
  DEVICE_LOCATION = 'DEVICE_LOCATION'
}

export enum DeviceGroup {
  UNKNOWN = 'UNKNOWN',
  DEVICE_C = 'DEVICE_C',
  DEVICE_B = 'DEVICE_B',
  NO_DEVICE = 'NO_DEVICE',
  EXTERNAL_TESTING = 'EXTERNAL_TESTING',
  INTERNAL_TESTING = 'INTERNAL_TESTING',
  DEVELOPMENT = 'DEVELOPMENT',
  DEVICE_LL = 'DEVICE_LL',
  CLINICAL_TRIAL = 'CLINICAL_TRIAL',
  EXTERNAL_DEMO_LL = 'EXTERNAL_DEMO_LL'
}

export enum AccountStatus {
  UNKNOWN = 'UNKNOWN',
  NO_STATUS = 'NO_STATUS',
  ACTIVE = 'ACTIVE',
  ACTIVE_DELAY = 'ACTIVE_DELAY',
  ACTIVE_14_DAY_TRIAL = 'ACTIVE_14_DAY_TRIAL',
  ACTIVE_14_DAY_TRIAL_PAID = 'ACTIVE_14_DAY_TRIAL_PAID',
  ACTIVE_PROGRAM = 'ACTIVE_PROGRAM',
  ACTIVE_DEMO = 'ACTIVE_DEMO',
  ACTIVE_EXTENSION = 'ACTIVE_EXTENSION',
  ACTIVE_RELAPSE_HIGH = 'ACTIVE_RELAPSE_HIGH',
  ACTIVE_RELAPSE_LOW = 'ACTIVE_RELAPSE_LOW',
  PAUSED = 'PAUSED',
  INACTIVE = 'INACTIVE'
}
/*
id UUID NOT NULL PRIMARY KEY,
    organization_id UUID REFERENCES organization(ID) ON DELETE CASCADE ON UPDATE CASCADE,
    name TEXT NOT NULL,
    description TEXT,
    created timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP,
    modified timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP
*/

export interface GetPersonsPayload extends Array<Person> {}
export interface GetPermissionRolePayload extends Array<PermissionRole> {}

export interface PushNotificationRequest {
  title: string;
  body?: Nullable<string>;
  payload?: Nullable<object>;
  pushNotificationType?: Nullable<PushNotificationType>;
  personIds?: Nullable<string[]>;
}

export enum PushNotificationType {
  UNKNOWN = 'UNKNOWN',
  MANUAL_PUSH_MESSAGE_CBTI = 'MANUAL_PUSH_MESSAGE_CBTI'
}

export enum DataUsageAgreementType {
  YES = 'YES',
  NO = 'NO',
  MAYBE = 'MAYBE'
}

export enum IsiScoreType {
  START = 'START',
  MID = 'MID',
  FINAL = 'FINAL'
}

export enum GenderType {
  UNKNOWN = 'UNKNOWN',
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NON_BINARY = 'NON_BINARY',
  PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY'
}

export const genderTypeList: GenderType[] = Object.values(GenderType).filter((option) => option !== GenderType.UNKNOWN);

export interface PutEsIdResponsePayload {
  personId: string;
  deviceId?: Nullable<string>;
  esId?: Nullable<string>;
}

export interface PutSleepLogDeviceTimesEnabledResponsePayload {
  personId: string;
  sleepLogDeviceTimesEnabledTs?: Nullable<string>;
}

export interface PutLunaSleepLogSummariesEnabledResponsePayload {
  personId: string;
  lunaSleepLogSummariesEnabledTs?: Nullable<string>;
}
export interface PutLunaSleepScheduleSummariesEnabledResponsePayload {
  personId: string;
  lunaSleepScheduleSummariesEnabledTs?: Nullable<string>;
}
export interface PutLunaWeeklySummariesEnabledResponsePayload {
  personId: string;
  lunaWeeklySummariesEnabledTs?: Nullable<string>;
}
export interface PutLunaSleepLogSummariesEnabledResponsePayload {
  personId: string;
  lunaSleepLogSummariesEnabledTs?: Nullable<string>;
}

export interface PutSleepLogAutoSubmissionEnabledResponsePayload {
  personId: string;
  sleepLogAutoSubmissionEnabledTs?: Nullable<string>;
}

export interface PutChatBotEnabledResponsePayload {
  personId: string;
  chatChannelType: ChatChannelType;
  chatBotEnabledTs?: Nullable<string>;
}

export interface NewPatientRequest {
  givenNames: string;
  familyName: string;
  phoneNumber: string;
  email: string;
  genderType: GenderType;
  carePlanId?: Nullable<string>;
  birthDate: string;
}

export interface NewTeamMemberRequest {
  givenNames: string;
  familyName: string;
  email: string;
  permissionRoleTypeId: string;
}

export interface PatientStatState {
  readonly patientStatByPersonId: Record<string, PersonVitals>;
  readonly isLoading: Record<string, boolean>;
  readonly error: Record<string, Error>;
  readonly success: Record<string, boolean>;
}

export interface PersonVitals {
  personId: string;
  organizationId: string;
  heartRate: number;
  breathingRate: number;
  sleepDurations?: number;
  sleepEfficiency?: number;
  personPresence: string;
  deviceStatus: string;
  lastReading: string;
}
