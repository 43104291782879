import { DateTime } from 'luxon';
import { ErrorPayload } from '../common/types';
import { ThunkParams } from '../types';
import {
  assignCarePlanAction,
  createNewActivityAction,
  deleteCareFeedItemThunkAction,
  enableDemoModeAction,
  fetchCarePlanActivitiesAction,
  fetchCarePlanMeasurementsAction,
  fetchCarePlansAction,
  fetchPatientCareFeedItemsAction,
  fetchPatientsAndCarePlansAction,
  processPostCarePlanMessageAction,
  unassignCarePlanAction
} from './carePlanActions';
import { AssignCarePlanPayload, CareFeedItemPayload, CarePlansPayload, MeasurementsPayload, NewCarePlanActivityRequest, PersonWithCarePlansPayload } from './carePlanTypes';

function processFetchCarePlansSuccess(payload: CarePlansPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchCarePlansAction.success(payload));
  };
}

function processFetchCarePlansFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchCarePlansAction.fail(error));
  };
}

export function fetchCarePlansThunk() {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchCarePlansAction.start());
    return apiClient.get('v1/carePlan/all', {
      auth: true,
      successAction: processFetchCarePlansSuccess,
      failAction: processFetchCarePlansFail
    });
  };
}

function processFetchPatientsAndCarePlansSuccess(payload: PersonWithCarePlansPayload[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchPatientsAndCarePlansAction.success(payload));
  };
}

function processFetchPatientsAndCarePlansFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchPatientsAndCarePlansAction.fail(error));
  };
}

// note: getting all patients with and without care plan
export function fetchPatientsAndCarePlansThunk() {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchPatientsAndCarePlansAction.start());
    return apiClient.get('v1/carePlan/patientsAndCarePlans', {
      auth: true,
      successAction: processFetchPatientsAndCarePlansSuccess,
      failAction: processFetchPatientsAndCarePlansFail
    });
  };
}

function processAssignCarePlanSuccess(payload: AssignCarePlanPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(assignCarePlanAction.success(payload));
  };
}

function processAssignCarePlanFail(carePlanId: string, personId: string, error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    /* @ts-expect-error: temp workaround for 404 error for patient without device setup */
    dispatch(assignCarePlanAction.fail({ error, carePlanId, personId }));
  };
}

export function assignCarePlanThunk(carePlanId: string, personId: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(assignCarePlanAction.start());
    return apiClient.post(`v1/carePlan/${carePlanId}/assign/${personId}`, {
      auth: true,
      successAction: processAssignCarePlanSuccess,
      failAction: (payload) => processAssignCarePlanFail(carePlanId, personId, payload)
    });
  };
}

function processUnassignCarePlanSuccess(payload: AssignCarePlanPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(unassignCarePlanAction.success(payload));
  };
}

function processUnassignCarePlanFail(carePlanId: string, personId: string, error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    /* @ts-expect-error: temp workaround for 404 error for patient without device setup */
    dispatch(unassignCarePlanAction.fail({ error, carePlanId, personId }));
  };
}

export function unassignCarePlanThunk(carePlanId: string, personId: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(unassignCarePlanAction.start());
    return apiClient.delete(`v1/carePlan/${carePlanId}/unassign/${personId}`, {
      auth: true,
      successAction: processUnassignCarePlanSuccess,
      failAction: processUnassignCarePlanFail
    });
  };
}

function processFetchCarePlanMeasurementsSuccess(payload: MeasurementsPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchCarePlanMeasurementsAction.success(payload));
  };
}

function processFetchCarePlanMeasurementsFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchCarePlanMeasurementsAction.fail(error));
  };
}

export function fetchCarePlanMeasurementsThunk() {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchCarePlanMeasurementsAction.start());
    return apiClient.get('v1/carePlan/measurements', {
      auth: true,
      successAction: processFetchCarePlanMeasurementsSuccess,
      failAction: processFetchCarePlanMeasurementsFail
    });
  };
}

function processEnableDemoModeSuccess(payload: any) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(enableDemoModeAction.success(payload));
  };
}

function processEnableDemoModeFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(enableDemoModeAction.fail(error));
  };
}

export function enableDemoModeThunk(personId: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(enableDemoModeAction.start());
    return apiClient.post(`v1/carePlan/enableDemoMode/${personId}`, {
      auth: true,
      successAction: processEnableDemoModeSuccess,
      failAction: processEnableDemoModeFail
    });
  };
}

function processPostCarePlanMessageSuccess(payload: any) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(processPostCarePlanMessageAction.success(payload));
  };
}

function processPostCarePlanMessageFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(processPostCarePlanMessageAction.fail(error));
  };
}

export function postCarePlanMessageThunk(personId: string, message: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(processPostCarePlanMessageAction.start());
    return apiClient.post(`v1/carePlan/message/${personId}`, {
      auth: true,
      data: message,
      successAction: processPostCarePlanMessageSuccess,
      failAction: processPostCarePlanMessageFail
    });
  };
}

function processFetchPatientCareFeedItemsSuccess(startUtcMs: number, payload: CareFeedItemPayload[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchPatientCareFeedItemsAction.success({ startUtcMs, payload }));
  };
}

function processFetchPatientCareFeedItemsFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchPatientCareFeedItemsAction.fail(error));
  };
}

export function fetchPatientCareFeedItemsThunk(personId: string, startUtcMs: number, endUtcMs: number) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchPatientCareFeedItemsAction.start());
    return apiClient.get(`v1/carePlan/${personId}/feedAndResponses`, {
      auth: true,
      params: { 'start-ts': startUtcMs, 'end-ts': endUtcMs },
      successAction: (payload) => processFetchPatientCareFeedItemsSuccess(startUtcMs, payload),
      failAction: processFetchPatientCareFeedItemsFail
    });
  };
}

function processDeleteCareFeedItemSuccess(careFeedItemId: string, startUtcMs: number) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(deleteCareFeedItemThunkAction.success({ careFeedItemId, startUtcMs }));
  };
}

function processDeleteCareFeedItemFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(deleteCareFeedItemThunkAction.fail(error));
  };
}

export function deleteCareFeedItemThunk(careFeedItemId: string, startUtcMs: number) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(deleteCareFeedItemThunkAction.start());
    return apiClient.delete(`v1/carePlan/feed/${careFeedItemId}`, {
      auth: true,
      successAction: () => processDeleteCareFeedItemSuccess(careFeedItemId, startUtcMs),
      failAction: processDeleteCareFeedItemFail
    });
  };
}

function processFetchCarePlanActivitiesSuccess(payload: any) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchCarePlanActivitiesAction.success(payload));
  };
}

function processFetchCarePlanActivitiesFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchCarePlanActivitiesAction.fail(error));
  };
}

export function fetchCarePlanActivitiesThunk() {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(fetchCarePlanActivitiesAction.start());
    return apiClient.get('v1/carePlan/carePlanActivities', {
      auth: true,
      successAction: processFetchCarePlanActivitiesSuccess,
      failAction: processFetchCarePlanActivitiesFail
    });
  };
}

function processCreateNewActivitySuccess(payload: any) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(createNewActivityAction.success(payload));
  };
}

function processCreateNewActivityFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(createNewActivityAction.fail(error));
  };
}

export function createNewActivityThunk(personId: string, newCarePlanActivityRequest: NewCarePlanActivityRequest) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(createNewActivityAction.start());
    return apiClient.post(`v1/carePlan/${personId}/activity`, {
      auth: true,
      data: newCarePlanActivityRequest,
      successAction: processCreateNewActivitySuccess,
      failAction: processCreateNewActivityFail
    });
  };
}
