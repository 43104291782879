import { DeviceContent } from '../content/contentTypes';
import { createReduxActionCreator } from '../types';
import { AssignCarePlanPayload, CareFeedItemPayload, CarePlansPayload, MeasurementsPayload, PersonWithCarePlansPayload } from './carePlanTypes';

export const CARE_PLAN_ACTION_PREFIX = 'care-plan-redux/';

export const assignCarePlanAction = createReduxActionCreator<AssignCarePlanPayload>(`${CARE_PLAN_ACTION_PREFIX}assign-care-plan`);
export const unassignCarePlanAction = createReduxActionCreator<AssignCarePlanPayload>(`${CARE_PLAN_ACTION_PREFIX}unassign-care-plan`);
export const enableDemoModeAction = createReduxActionCreator<any>(`${CARE_PLAN_ACTION_PREFIX}enable-demo-mode`);
export const fetchCarePlansAction = createReduxActionCreator<CarePlansPayload>(`${CARE_PLAN_ACTION_PREFIX}fetch-care-plans`);
export const fetchPatientsAndCarePlansAction = createReduxActionCreator<PersonWithCarePlansPayload[]>(`${CARE_PLAN_ACTION_PREFIX}fetch-patients-and-care-plans`);
export const fetchPatientCareFeedItemsAction = createReduxActionCreator<{ startUtcMs: number; payload: CareFeedItemPayload[] }>(
  `${CARE_PLAN_ACTION_PREFIX}fetch-patient-care-feed-items`
);
export const fetchCarePlanMeasurementsAction = createReduxActionCreator<MeasurementsPayload>(`${CARE_PLAN_ACTION_PREFIX}fetch-care-plan-measurements`);
export const processPostCarePlanMessageAction = createReduxActionCreator<any>(`${CARE_PLAN_ACTION_PREFIX}post-care-plan-message`);
export const deleteCareFeedItemThunkAction = createReduxActionCreator<{ careFeedItemId: string; startUtcMs: number }>(`${CARE_PLAN_ACTION_PREFIX}delete-care-feed-item`);
export const fetchCarePlanActivitiesAction = createReduxActionCreator<DeviceContent[]>(`${CARE_PLAN_ACTION_PREFIX}fetch-care-plan-activities`);
export const createNewActivityAction = createReduxActionCreator<any>(`${CARE_PLAN_ACTION_PREFIX}create-new-activity`);
