import { createReducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { fetchCarePlansAction, fetchPatientCareFeedItemsAction } from '../../redux/carePlan/carePlanActions';
import { normalizeEnumName } from '../../utils/content';
import { clearPersonResponseStatusAction, putAccountStatusActions, putDeviceGroupActions, saveSleepScheduleAndCalculationsActions } from '../person/personActions';
import { SleepSchedule, SleepScheduleCalculationResponse, WorkflowUpdatingResponse } from '../person/personTypes';
import { getActionStringWithoutState } from '../reduxUtils';
import {
  CONTENT_ACTION_PREFIX,
  addDeviceContentActions,
  addDeviceContentPageActions,
  copyDeviceContentDetailsActions,
  getAllFlagContentActions,
  getClearDeviceContentDetailsAction,
  getCreateAdHocContentActions,
  getDeviceContentByTypeActions,
  getDeviceContentDetailsActions,
  getDeviceContentPageActions,
  getFeedItemsActions,
  getFlagContentActions,
  getPersonFlagContentActions,
  getProgramContentByTypeActions,
  getSurveyResponsesByTypeActions,
  removeDeviceContentPageActions,
  saveDeviceAndProgramContentsActions,
  saveFlagContentActions,
  savePersonTaskStatusActions,
  saveWorkflowCommentActions,
  updateDeviceContentActions,
  updateDeviceContentPageActions
} from './contentActions';
import {
  ContentState,
  DeviceAndProgramContentUpdateRequest,
  DeviceContent,
  DeviceContentPage,
  DeviceContentType,
  PersonWorkflow,
  PersonWorkflowComment,
  PersonWorkflowTaskStatus,
  ProgramContent,
  ProgramType,
  WorkflowTaskStatusType,
  WorkflowTaskTemplate,
  WorkflowTemplate
} from './contentTypes';

export const initialContentState: ContentState = {
  isLoading: {},
  error: {},
  success: {},
  isCreatingAdHocContent: false,
  programContentByType: {
    UNKNOWN: [],
    CBTI_CONTENT: [],
    WIND_DOWN: [],
    BED_RESET: [],
    MANUAL_SLEEP_LOG: [],
    AD_HOC_CONTENT: [],
    CBTI_HYBRID: [],
    CBTI_NATIVE: [],
    SLEEP_NEED_SURVEY: [],
    AD_HOC_NATIVE: [],
    CBTI_14_DAY_TRIAL: [],
    ISI_SURVEY: [],
    DEMO_CONTENT: []
  },
  deviceContentByType: {
    UNKNOWN: [],
    WIND_DOWN_AUDIO: [],
    SLEEP_SOUND_AUDIO: [],
    BED_RESET_AUDIO: [],
    BED_RESET_CELEBRATION_AUDIO: [],
    CARE_PLAN_UNIT: [],
    CBTI_HTML: [],
    CBTI_SURVEY: [],
    MANUAL_SLEEP_LOG: [],
    AD_HOC_CONTENT: [],
    WAKE_ALARM_AUDIO: [],
    CBTI_NATIVE: [],
    AD_HOC_NATIVE: [],
    CBTI_14_DAY_TRIAL: [],
    ISI_SURVEY: [],
    DEMO_CONTENT: [],
    CARE_ASSESSMENT: [],
    CARE_QUESTION: [],
    CARE_CUSTOM_MESSAGE: []
  },
  carePlanDeviceContentById: {},
  flagById: {},
  flagsByPersonId: {},
  surveyResponses: [],
  personWorkflows: [],
  successMessage: undefined
};

const updateDeviceContent = (contentStore: DeviceContent[], index: number, programContent: ProgramContent): void => {
  const indexedDeviceContent: DeviceContent = contentStore[index];
  const deviceContentPrograms: ProgramContent[] = cloneDeep(indexedDeviceContent.programContents || []);
  const programContentIndex: number = deviceContentPrograms.findIndex((x) => x.id === programContent.id);
  if (programContentIndex < 0 && !programContent.endDateTs) {
    deviceContentPrograms.push(programContent);
  } else if (programContentIndex >= 0 && programContent.endDateTs) {
    deviceContentPrograms.splice(programContentIndex, 1);
  } else if (programContentIndex >= 0 && !programContent.endDateTs) {
    deviceContentPrograms[programContentIndex] = programContent;
  }
  indexedDeviceContent.programContents = deviceContentPrograms;
  contentStore[index] = indexedDeviceContent;
};

const updateDeviceContentPageForType = (updated: DeviceContentPage, deviceContentType: DeviceContentType, state: ContentState) => {
  const updateDeviceIndex: number = state.deviceContentByType[deviceContentType].findIndex((deviceContent) => deviceContent.id === updated.deviceContentId);
  if (updateDeviceIndex >= 0) {
    const updateDevicePageIndex: Nullable<number> = state.deviceContentByType[deviceContentType][updateDeviceIndex].deviceContentPages?.findIndex(
      (contentPage) => contentPage.id === updated.id
    );
    if (updateDevicePageIndex && updateDevicePageIndex >= 0) {
      const deviceContentPages = state.deviceContentByType[deviceContentType][updateDeviceIndex].deviceContentPages;
      if (deviceContentPages && deviceContentPages[updateDevicePageIndex]) {
        deviceContentPages[updateDevicePageIndex] = updated;
      }
    }
  }
};

const addDeviceContentPageForType = (added: DeviceContentPage, deviceContentType: DeviceContentType, state: ContentState): void => {
  const deviceContents: DeviceContent[] = state.deviceContentByType[deviceContentType] ? state.deviceContentByType[deviceContentType] : [];
  const updateDeviceIndex: number | undefined = deviceContents?.findIndex((deviceContent) => deviceContent.id === added.deviceContentId);
  if (updateDeviceIndex && updateDeviceIndex >= 0) {
    const deviceContent: DeviceContent | undefined = deviceContents?.[updateDeviceIndex];
    if (deviceContent) {
      const deviceContentPages: DeviceContentPage[] = deviceContent.deviceContentPages || [];
      deviceContentPages.push(added);
      deviceContent.deviceContentPages = deviceContentPages;
      if (deviceContents) {
        deviceContents[updateDeviceIndex] = deviceContent;
        state.deviceContentByType[deviceContentType] = deviceContents;
      }
    }
  }
};

const removeDeviceContentPageForType = (removedPageId: string, deviceContentType: DeviceContentType, state: ContentState): void => {
  let removedDeviceIndex;
  state.deviceContentByType[deviceContentType].map((deviceContent) => {
    if (deviceContent.deviceContentPages) {
      removedDeviceIndex = deviceContent.deviceContentPages.findIndex((pageContent) => pageContent.pageId == removedPageId);
    }
  });
  if (removedDeviceIndex && removedDeviceIndex >= 0) {
    let removedPageIndex = state.deviceContentByType[deviceContentType][removedDeviceIndex]?.deviceContentPages?.findIndex((pageContent) => pageContent.pageId == removedPageId);
    if (removedPageIndex && removedPageIndex >= 0) {
      const deviceContentPages = state.deviceContentByType[deviceContentType][removedDeviceIndex].deviceContentPages;
      if (deviceContentPages && deviceContentPages[removedPageIndex]) {
        deviceContentPages[removedPageIndex].releasedTs = undefined;
      }
    }
  }
};

const updateFlagCache = (flag: Nullable<WorkflowTemplate>, state: ContentState): void => {
  if (flag) {
    state.flagById[flag.id || ''] = flag;
    const personFlags: PersonWorkflow[] = flag?.personWorkflows || [];
    personFlags?.forEach((personFlag) => {
      const flagIds: Set<string> = state.flagsByPersonId[personFlag.personId] || new Set<string>();
      flagIds.add(personFlag.workflowId || '');
      state.flagsByPersonId[personFlag.personId] = flagIds;
    });
  }
};

export default createReducer(initialContentState, (builder) => {
  builder
    .addCase(getCreateAdHocContentActions.start, (state, action) => {
      state.isCreatingAdHocContent = true;
      state.creatingAdHocContentError = undefined;
    })
    .addCase(getCreateAdHocContentActions.success, (state, action) => {
      state.isCreatingAdHocContent = false;
      state.creatingAdHocContentError = undefined;
    })
    .addCase(getCreateAdHocContentActions.fail, (state, action) => {
      state.isCreatingAdHocContent = false;
      state.creatingAdHocContentError = action.payload;
    })
    .addCase(getProgramContentByTypeActions.start, (state, action) => {
      const programType: ProgramType = action.payload;
      state.programContentByType[programType] = [];
    })
    .addCase(getProgramContentByTypeActions.success, (state, action) => {
      const programContents: ProgramContent[] = action.payload;
      if (programContents && programContents.length) {
        state.programContentByType[programContents[0].programType] = programContents;
      }
    })
    .addCase(getDeviceContentByTypeActions.start, (state, action) => {
      const deviceContentTypes: Nullable<DeviceContentType[]> = action.payload;
      deviceContentTypes?.map((deviceContentType) => {
        state.deviceContentByType[deviceContentType] = [];
      });
    })
    .addCase(getDeviceContentByTypeActions.success, (state, action) => {
      const deviceContents: DeviceContent[] = action.payload;
      deviceContents.map((deviceContent) => {
        if (state.deviceContentByType[deviceContent.deviceContentType]) {
          state.deviceContentByType[deviceContent.deviceContentType].push(deviceContent);
        } else {
          state.deviceContentByType[deviceContent.deviceContentType] = [deviceContent];
        }
      });
    })
    .addCase(updateDeviceContentActions.start, (state, action) => {
      state.saveSuccessful = undefined;
    })
    .addCase(updateDeviceContentActions.success, (state, action) => {
      const updated: DeviceContent = action.payload;
      updated.deviceContentPages = updated?.deviceContentPages?.filter((page) => page.releasedTs) || [];
      const updateIndex: number = state.deviceContentByType[updated.deviceContentType].findIndex((content) => content.id === updated.id);
      if (updateIndex >= 0) {
        state.deviceContentByType[updated.deviceContentType][updateIndex] = updated;
      }
      state.selectedDeviceContent = updated;
      state.saveSuccessful = true;
    })
    .addCase(updateDeviceContentActions.fail, (state, action) => {
      state.saveSuccessful = false;
    })
    .addCase(saveDeviceAndProgramContentsActions.start, (state, action) => {
      state.saveSuccessful = undefined;
    })
    .addCase(saveDeviceAndProgramContentsActions.success, (state, action) => {
      const updates: DeviceAndProgramContentUpdateRequest = action.payload;
      const deviceUpdates: DeviceContent[] = updates.deviceContent;
      const cbtiNativeContent: DeviceContent[] = cloneDeep(state.deviceContentByType[DeviceContentType.CBTI_NATIVE] || []);
      const adHocContent: DeviceContent[] = cloneDeep(state.deviceContentByType[DeviceContentType.AD_HOC_NATIVE] || []);
      deviceUpdates.forEach((updated) => {
        const cbtiIndex: number = cbtiNativeContent.findIndex((content) => content.id === updated.id);
        if (cbtiIndex >= 0) {
          if (updated.deviceContentType !== DeviceContentType.CBTI_NATIVE) {
            cbtiNativeContent.splice(cbtiIndex, 1);
            adHocContent.push(updated);
          } else {
            cbtiNativeContent[cbtiIndex] = updated;
          }
        } else {
          const adhocIndex: number = adHocContent.findIndex((content) => content.id === updated.id);
          if (adhocIndex >= 0) {
            if (updated.deviceContentType !== DeviceContentType.AD_HOC_NATIVE) {
              adHocContent.splice(adhocIndex, 1);
              cbtiNativeContent.push(updated);
            } else {
              adHocContent[adhocIndex] = updated;
            }
          }
        }
      });

      const programContents: ProgramContent[] = updates.programContent;
      programContents?.forEach((programContent) => {
        const programDeviceContentId: Nullable<string> = programContent.deviceContentId;
        if (programDeviceContentId) {
          const deviceContentCbtiIndex: number = cbtiNativeContent.findIndex((x) => x.id === programDeviceContentId);
          if (deviceContentCbtiIndex >= 0) {
            updateDeviceContent(cbtiNativeContent, deviceContentCbtiIndex, programContent);
          }
          const deviceContentAdHocIndex: number = adHocContent.findIndex((x) => x.id === programDeviceContentId);
          if (deviceContentAdHocIndex >= 0) {
            updateDeviceContent(adHocContent, deviceContentAdHocIndex, programContent);
          }
        }
      });

      state.deviceContentByType[DeviceContentType.CBTI_NATIVE] = cbtiNativeContent;
      state.deviceContentByType[DeviceContentType.AD_HOC_NATIVE] = adHocContent;

      if (programContents && programContents.length) {
        state.programContentByType[programContents[0].programType] = programContents.filter((x) => !x.endDateTs);
      }
      if (state.selectedDeviceContent) {
        const updatedCbtiDeviceContent: DeviceContent | undefined = cbtiNativeContent.find((x) => x.id === state.selectedDeviceContent?.id);
        if (updatedCbtiDeviceContent) {
          state.selectedDeviceContent = updatedCbtiDeviceContent;
        } else {
          const updatedAdHocDeviceContent: DeviceContent | undefined = adHocContent.find((x) => x.id === state.selectedDeviceContent?.id);
          if (updatedAdHocDeviceContent) {
            state.selectedDeviceContent = updatedAdHocDeviceContent;
          }
        }
      }

      state.saveSuccessful = true;
    })
    .addCase(saveDeviceAndProgramContentsActions.fail, (state, action) => {
      state.saveSuccessful = false;
    })
    .addCase(addDeviceContentActions.start, (state, action) => {
      state.saveSuccessful = undefined;
    })
    .addCase(addDeviceContentActions.success, (state, action) => {
      const added: DeviceContent = action.payload;
      state.deviceContentByType[added.deviceContentType].push(added);
      state.selectedDeviceContent = added;
      state.saveSuccessful = true;
    })
    .addCase(addDeviceContentActions.fail, (state, action) => {
      state.saveSuccessful = false;
    })
    .addCase(getDeviceContentPageActions.start, (state, action) => {
      state.selectedDeviceContentPage = undefined;
    })
    .addCase(getDeviceContentPageActions.success, (state, action) => {
      state.selectedDeviceContentPage = action.payload;
    })
    .addCase(updateDeviceContentPageActions.start, (state, action) => {
      state.saveSuccessful = undefined;
    })
    .addCase(updateDeviceContentPageActions.success, (state, action) => {
      const updated: DeviceContentPage = action.payload;

      updateDeviceContentPageForType(updated, DeviceContentType.CBTI_NATIVE, state);
      updateDeviceContentPageForType(updated, DeviceContentType.AD_HOC_NATIVE, state);

      const deviceContent: Nullable<DeviceContent> = state.selectedDeviceContent;
      if (deviceContent && deviceContent.id === updated.deviceContentId && deviceContent.deviceContentPages?.length) {
        const updatePageIndex: number = deviceContent.deviceContentPages.findIndex((devicePage) => devicePage.id === updated.id);
        if (updatePageIndex >= 0) {
          deviceContent.deviceContentPages[updatePageIndex] = updated;
          state.selectedDeviceContent = deviceContent;
        }
      }
      state.selectedDeviceContentPage = updated;
      state.saveSuccessful = true;
    })
    .addCase(updateDeviceContentPageActions.fail, (state, action) => {
      state.saveSuccessful = false;
    })
    .addCase(addDeviceContentPageActions.start, (state, action) => {
      state.saveSuccessful = undefined;
    })
    .addCase(addDeviceContentPageActions.success, (state, action) => {
      const added: DeviceContentPage = action.payload;

      addDeviceContentPageForType(added, DeviceContentType.CBTI_NATIVE, state);
      addDeviceContentPageForType(added, DeviceContentType.AD_HOC_NATIVE, state);

      const deviceContent: Nullable<DeviceContent> = state.selectedDeviceContent;
      if (deviceContent && deviceContent.id === added.deviceContentId) {
        const deviceContentPages: DeviceContentPage[] = deviceContent.deviceContentPages || [];
        deviceContentPages.push(added);
        deviceContent.deviceContentPages = deviceContentPages;
        state.selectedDeviceContent = deviceContent;
      }
      state.selectedDeviceContentPage = added;
      state.saveSuccessful = true;
    })
    .addCase(addDeviceContentPageActions.fail, (state, action) => {
      state.saveSuccessful = false;
    })
    .addCase(removeDeviceContentPageActions.success, (state, action) => {
      const removedPageId: string = action.payload;
      removeDeviceContentPageForType(removedPageId, DeviceContentType.CBTI_NATIVE, state);
      removeDeviceContentPageForType(removedPageId, DeviceContentType.AD_HOC_NATIVE, state);
    })
    .addCase(getSurveyResponsesByTypeActions.success, (state, action) => {
      state.surveyResponses = action.payload;
    })
    .addCase(getFeedItemsActions.start, (state, action) => {
      state.feedItems = [];
    })
    .addCase(getFeedItemsActions.success, (state, action) => {
      state.feedItems = action.payload;
    })
    .addCase(getClearDeviceContentDetailsAction, (state, action) => {
      state.selectedDeviceContent = undefined;
    })
    .addCase(getDeviceContentDetailsActions.start, (state, action) => {
      state.selectedDeviceContent = undefined;
    })
    .addCase(getDeviceContentDetailsActions.success, (state, action) => {
      state.selectedDeviceContent = action.payload;
    })
    .addCase(getAllFlagContentActions.start, (state, action) => {
      for (const key in state.flagById) {
        delete state.flagById[key];
      }
      for (const key in state.flagsByPersonId) {
        delete state.flagsByPersonId[key];
      }
    })
    .addCase(getAllFlagContentActions.success, (state, action) => {
      const flags: WorkflowTemplate[] = action.payload;
      flags?.forEach((flag) => {
        updateFlagCache(flag, state);
      });
    })
    .addCase(getFlagContentActions.success, (state, action) => {
      const flag: WorkflowTemplate = action.payload;
      if (flag?.id) {
        state.flagById[flag.id] = flag;
      }
    })
    .addCase(getPersonFlagContentActions.start, (state, action) => {
      state.personWorkflows = [];
      delete state.success[action.type];
    })
    .addCase(getPersonFlagContentActions.success, (state, action) => {
      const personWorkflows: PersonWorkflow[] = action.payload;
      personWorkflows?.forEach((personWorkflow) => {
        const flag: Nullable<WorkflowTemplate> = personWorkflow.workflow;
        updateFlagCache(flag, state);
      });
      state.personWorkflows = personWorkflows;
    })
    .addCase(getPersonFlagContentActions.fail, (state, action) => {
      state.error[action.type] = {
        message: action.payload?.message,
        status: action.payload.status
      };
      state.success[action.type] = false;
    })
    .addCase(putAccountStatusActions.success, (state, action) => {
      const workflowUpdatingResponse: WorkflowUpdatingResponse<any> = action.payload;
      state.personWorkflows = workflowUpdatingResponse.updatedWorkflows;
    })
    .addCase(putDeviceGroupActions.success, (state, action) => {
      const workflowUpdatingResponse: WorkflowUpdatingResponse<any> = action.payload;
      state.personWorkflows = workflowUpdatingResponse.updatedWorkflows;
    })
    .addCase(saveSleepScheduleAndCalculationsActions.success, (state, action) => {
      const sleepScheduleCalculationResponse: SleepScheduleCalculationResponse = action.payload;
      const sleepScheduleResponse: WorkflowUpdatingResponse<SleepSchedule> = sleepScheduleCalculationResponse.sleepScheduleUpdateResponse;
      state.personWorkflows = sleepScheduleResponse.updatedWorkflows;
    })
    .addCase(saveFlagContentActions.success, (state, action) => {
      const flag: WorkflowTemplate = action.payload.workflowTemplate;
      const personId: Nullable<string> = action.payload.personId;
      if (flag?.id) {
        state.flagById[flag.id] = flag;
      }
      const flagNonRecursive: WorkflowTemplate = cloneDeep(flag);
      const tasksNonRecursive: WorkflowTaskTemplate[] = cloneDeep(flag.workflowTasks);
      flagNonRecursive.personWorkflows = undefined;
      flagNonRecursive.workflowTasks = undefined;
      flag.personWorkflows?.forEach((personWorkflow) => {
        personWorkflow.workflow = flagNonRecursive;
        personWorkflow.personWorkflowTasks?.forEach((personWorkflowTask) => {
          if (personWorkflowTask) {
            personWorkflowTask.workflowTask = tasksNonRecursive?.find((task) => task.id === personWorkflowTask.workflowTaskId);
          }
        });
        const index: number = state.personWorkflows.findIndex((pWorkflow) => pWorkflow.id === personWorkflow.id && pWorkflow.personId === personWorkflow.personId);
        if (index >= 0) {
          state.personWorkflows[index] = personWorkflow;
        } else if (!personId || personId === personWorkflow.personId) {
          state.personWorkflows.push(personWorkflow);
        }
      });
      state.personWorkflows?.sort((s1, s2) => (s1?.created && s2?.created ? s2.created?.localeCompare(s1.created) : 0));
      state.saveSuccessful = true;
      state.success[action.type] = true;
    })
    .addCase(saveWorkflowCommentActions.success, (state, action) => {
      const comment: PersonWorkflowComment = action.payload;
      const personWorkflows: PersonWorkflow[] = state?.personWorkflows;
      const index: number = personWorkflows?.findIndex((p) => comment.personWorkflowId === p.id);
      if (index >= 0) {
        const comments: PersonWorkflowComment[] = personWorkflows[index].personWorkflowComments || [];
        comments.unshift(comment);
        personWorkflows[index].personWorkflowComments = comments;
      }
      state.success[action.type] = true;
    })
    .addCase(savePersonTaskStatusActions.success, (state, action) => {
      const updatedWorkflow: PersonWorkflow = action.payload.personWorkflow;
      const updatedPersonWorkflowTaskStatus: PersonWorkflowTaskStatus = action.payload.personWorkflowTaskStatus;
      const personWorkflows: PersonWorkflow[] = state?.personWorkflows;
      const index: number = personWorkflows?.findIndex((p) => updatedWorkflow.id === p.id);
      if (index >= 0) {
        personWorkflows[index] = updatedWorkflow;
      }
      const updatedPersonWorkflowTask = updatedWorkflow.personWorkflowTasks?.find((x) => x?.id === updatedPersonWorkflowTaskStatus.personWorkflowTaskId);
      const updatedTaskTitle: Nullable<string> = updatedPersonWorkflowTask?.workflowTask?.title;
      const updatedTaskAction: WorkflowTaskStatusType = updatedPersonWorkflowTaskStatus.workflowTaskStatusType;
      state.successMessage = `"${updatedTaskTitle}" ${normalizeEnumName(updatedTaskAction)}`;
      state.success[action.type] = false;
    })
    .addCase(copyDeviceContentDetailsActions.start, (state, action) => {
      state.saveSuccessful = undefined;
      state.copiedDeviceContentId = undefined;
    })
    .addCase(copyDeviceContentDetailsActions.success, (state, action) => {
      state.saveSuccessful = true;
      state.copiedDeviceContentId = action.payload;
    })
    .addCase(clearPersonResponseStatusAction, (state, action) => {
      state.saveSuccessful = undefined;
      state.copiedDeviceContentId = undefined;
      state.successMessage = undefined;
      for (const key in state.isLoading) {
        delete state.isLoading[key];
      }
      for (const key in state.error) {
        delete state.error[key];
      }
      for (const key in state.success) {
        delete state.success[key];
      }
    })
    .addCase(fetchCarePlansAction.success, (state, action) => {
      action.payload.forEach((carePlanPayload) => {
        const { carePlanSteps, ...rest } = carePlanPayload;
        carePlanSteps?.forEach((carePlanStepPayload) => {
          const { carePlanUnit, trigger, ...rest } = carePlanStepPayload || {};
          if (carePlanUnit) {
            const { deviceContent, ...rest } = carePlanUnit;
            if (deviceContent?.id) {
              state.carePlanDeviceContentById[deviceContent.id] = deviceContent;
            }
          }
        });
      });
    })
    .addCase(fetchPatientCareFeedItemsAction.success, (state, action) => {
      const { startUtcMs, payload } = action.payload;
      payload.forEach((payload) => {
        const { deviceContent, carePlanStep, trigger, ...rest } = payload;
        if (deviceContent?.id) {
          state.carePlanDeviceContentById[deviceContent.id] = deviceContent;
        }
      });
    })
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-start') && action.type.startsWith(CONTENT_ACTION_PREFIX),
      (state, action) => {
        const tag = getActionStringWithoutState(action.type);
        state.isLoading[tag] = true;
        delete state.error[tag];
        state.successMessage = undefined;
        state.saveSuccessful = undefined;
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-success') && action.type.startsWith(CONTENT_ACTION_PREFIX),
      (state, action) => {
        const tag = getActionStringWithoutState(action.type);
        state.isLoading[tag] = false;
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-fail') && action.type.startsWith(CONTENT_ACTION_PREFIX),
      (state, action: any) => {
        const tag = getActionStringWithoutState(action.type);
        delete state.isLoading[tag];
        state.error[tag] = {
          message: action.payload?.message,
          status: action.payload?.status
        };
        state.success[tag] = false;
        state.saveSuccessful = false;
        state.isLoading[tag] = true;
      }
    );
});
