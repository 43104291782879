import React, { useEffect } from 'react';
import { Text } from '../../../../components/Typography';
import arrowDownIcon from '../../../../assets/images/arrow-down.png';
import arrowUpIcon from '../../../../assets/images/arrow-up.png';
import style from './TableHeader.scss';

import classNames from 'classnames';

export type TableHeaderSort = 'ascending' | 'descending' | 'none';

export type TableHeaderProps = {
  children: string;
  onSort?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  sort?: TableHeaderSort;
};

const TableHeader: React.FC<TableHeaderProps> = ({ children, onSort, sort }) => {
  const previousSort = React.useRef(sort);

  let icon: any = null;

  if (sort === 'descending') {
    icon = <img src={arrowDownIcon} className={style.icon} />;
  } else if (sort === 'ascending') {
    icon = <img src={arrowUpIcon} className={style.icon} />;
  }

  useEffect(() => {
    if (previousSort.current !== sort) {
      previousSort.current = sort;
    }
  }, [children, sort]);

  return (
    <th className={style.tableHeader} scope="col" {...(onSort && { 'aria-sort': sort })}>
      {onSort ? (
        <button className={classNames(style.tableHeaderPadding, style.button, (sort === 'ascending' || sort === 'descending') && style.sorted)} onClick={onSort} type="button">
          <Text variant="xs" className={style.text}>
            {children}
          </Text>
          {icon}
        </button>
      ) : (
        <div className={style.tableHeaderPadding}>
          <Text variant="xs" className={style.text}>
            {children}
          </Text>
        </div>
      )}
    </th>
  );
};

export default TableHeader;
